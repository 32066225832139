import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Table, Rate } from "antd";
import type { TableProps, MenuProps } from "antd";
import dayjs from "dayjs";
import useEventHook from "../../Services/Api/event.api";
import EventModal from "../../Modals/event.modal";
import NiceModal from "@ebay/nice-modal-react";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";

const items: MenuProps["items"] = [
  { key: "1", label: "Duplicate", disabled: true },
  { key: "2", label: "Edit" },
  { key: "3", label: "Delete", disabled: true },
];

interface IEventData {
  events: Record<string, any>[];
  count: number;
  loading: boolean;
  error?: any;
}

interface TableParams {
  pagination?: any;
  sortField?: string;
  sortOrder?: string;
  filters?: any; //Parameters<GetProp<TableProps, "onChange">>[1];
}

const AdminEvents = () => {
  const { t, i18n } = useTranslation();

  const [searchParams] = useSearchParams();
  const [data, setData] = useState<IEventData>({
    events: [],
    count: 0,
    loading: true,
    error: null,
  });

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const { list } = useEventHook();

  const fetchEvents = async () => {
    const { data: events, count } = await list({
      where: { ...tableParams.filters, date: { $lt: dayjs().format() } },
      skip: (tableParams.pagination?.current - 1) * 10,
      limit: tableParams.pagination.pageSize,
      sort: { date: "desc" },
      populate: "image reviews author",
    });

    setData({ events, count: count || 0, loading: false });
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: count,
      },
    });
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters: any,
    sorter
  ) => {
    console.log({ pagination, filters, sorter });

    if (filters.topics) {
      filters.topics = { $in: filters.topics };
    } else {
      filters.topics = undefined;
    }

    if (filters.categories) {
      filters.categories = { $in: filters.categories };
    } else {
      filters.categories = undefined;
    }

    if (!filters.langage) {
      filters.language = undefined;
    }
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.current !== tableParams.pagination?.current) {
      setData({ ...data, events: [] });
    }
  };

  const showEventModal = async (event?) => {
    // Show a modal with arguments passed to the component as props
    NiceModal.show(EventModal, { event }).then(async (e) => {
      setData({ ...data, loading: true });
      const { data: events, count } = await list(searchParams.toString());
      setData({ events, count: count || 0, loading: false });
    });
  };

  const handleMenuClick = (e, row) => {
    if (e.key === "2") {
      showEventModal(row);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, [JSON.stringify(tableParams)]);

  const columns = [
    {
      title: t("IMAGE", "Image"),
      dataIndex: "image",
      key: "image",
      render: (image) => {
        return (
          <img
            style={{
              width: "60px",
              margin: "5px",
              borderRadius: "5px",
            }}
            src={image.thumbnailUrl}
          />
        );
      },
    },
    {
      title: t("TITLE", "Title"),
      dataIndex: "locales",
      key: "title",
      render: (locales, row) => {
        const locale =
          locales[i18n.language.split("-")[0] || "en"] || locales.en;
        const { isFeatured } = row;
        return (
          <div>
            {isFeatured ? (
              <FontAwesomeIcon
                icon={faStar}
                size="lg"
                color="orange"
                style={{ width: "40px", margin: "0px" }}
              />
            ) : (
              <div></div>
            )}
            <Link to={`/dashboard/events/${row._id}`}>{locale.title}</Link>
          </div>
        );
      },
    },
    {
      title: t("AUTHOR", "Author"),
      dataIndex: "author",
      key: "author",
      render: (author) => {
        return <div>{`${author.firstName} ${author.lastName}`}</div>;
      },
    },
    {
      title: t("DATE", "Date"),
      dataIndex: "date",
      key: "date",
      render: (cell) => {
        return dayjs(cell).format("MMM Do, YYYY");
      },
    },
    {
      title: t("TIME", "Time"),
      dataIndex: "startTime",
      key: "startTime",
      render: (cell, row) => {
        return `${dayjs(row.startTime).format("HH:mm")} - ${dayjs(
          row.endTime
        ).format("HH:mm")}`;
      },
    },
    {
      title: t("REGISTERED_ATTENDEES", "Registered Attendees"),
      dataIndex: "attendees",
      key: "attendees",
      render: (attendees) => {
        return <>{_.uniq(attendees).length}</>;
      },
    },
    {
      title: t("AVERAGE_REVIEWS", "Average Reviews"),
      dataIndex: "reviews",
      key: "reviews",
      render: (reviews) => {
        let total = 0;
        let count = reviews.length;
        reviews.forEach((review) => (total += review.rating));
        const average = total / count;
        return <Rate disabled defaultValue={average} />;
      },
    },
  ];

  return (
    <div style={{ width: "100%", padding: "20px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <h1>{t("COMPLETED_EVENTS", "Completed Events")}</h1>
      </div>
      <Table
        dataSource={data.events.sort((a, b) => {
          if (dayjs(a.date).isBefore(dayjs(b.date))) {
            return 1;
          }
          return -1;
        })}
        columns={columns}
        loading={data.loading}
        pagination={tableParams.pagination}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default AdminEvents;
