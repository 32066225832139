import React from "react";
import { Select } from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const timezones = ["Europe/London", "Europe/Paris"];

const TimezonePicker = (props) => {
  const { t } = useTranslation();

  const timeZone = dayjs.tz.guess();

  console.log({ timeZone });

  const changeTimezone = (e) => {};

  return (
    <Select
      {...props}
      style={{ width: "100%" }}
      size="large"
      onChange={changeTimezone}
      value={timeZone}
    >
      {timezones.map((tz) => (
        <Select.Option value={tz}>{tz}</Select.Option>
      ))}
    </Select>
  );
};

export default TimezonePicker;
