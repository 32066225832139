import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Table, Button } from "antd";
import { listCompanies } from "../../Services/Api/company.api";
import CompanyModal from "../../Modals/company.modal";
import NiceModal from "@ebay/nice-modal-react";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

interface TableParams {
  pagination?: any;
  sortField?: string;
  sortOrder?: string;
  filters?: any; //Parameters<GetProp<TableProps, "onChange">>[1];
}
const AdminCompanies = () => {
  const { t } = useTranslation();
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const showCompanyModal = () => {
    // Show a modal with arguments passed to the component as props
    NiceModal.show(CompanyModal).then(() => {
      companies.refetch();
    });
  };

  const fetchCompanies = async ({ queryKey }) => {
    const [_key, tableParams] = queryKey;

    return listCompanies({
      where: { ...tableParams.filters },
      skip: (tableParams.pagination?.current - 1) * 10,
      limit: tableParams.pagination.pageSize,
      populate: "",
    });
  };

  const companies = useQuery({
    queryKey: ["companies", tableParams],
    queryFn: fetchCompanies,
  });

  const columns = [
    {
      title: t("COMPANY_NAME", "Company Name"),
      dataIndex: "name",
      key: "name",
      render: (name, row) => {
        return <Link to={`/dashboard/admin/companies/${row._id}`}>{name}</Link>;
      },
    },
    {
      title: t("NUMBER_OF_USERS", "Number of Users"),
      dataIndex: "_userCount",
      key: "_userCount",
    },
  ];

  if (companies.error) {
    console.error(companies.error);
    return <div>An error occurred</div>;
  }

  return (
    <div style={{ width: "100%", padding: "20px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <h1>{t("COMPANIES", "Companies")}</h1>
        <div>
          <Button
            type="primary"
            onClick={() => {
              showCompanyModal();
            }}
          >
            Create Company
          </Button>
        </div>
      </div>
      <Table
        dataSource={companies.data?.data}
        columns={columns}
        loading={companies.isPending}
      />
      ;
    </div>
  );
};

export default AdminCompanies;
