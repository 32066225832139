import React, { useState, useEffect } from "react";
import Markdown from "react-markdown";
import axios from "axios";

const PrivacyPolicy = () => {
  const [policy, setPolicy] = useState();

  const fetchPolicy = async () => {
    const { data } = await axios.get("/static/privacy-policy.md");
    setPolicy(data);
  };

  useEffect(() => {
    fetchPolicy();
  }, []);

  return (
    <div style={{ margin: "100px" }}>
      <Markdown>{policy}</Markdown>
    </div>
  );
};

export default PrivacyPolicy;
