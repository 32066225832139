import React, { useState } from "react";
import { fetchAuthSession } from "aws-amplify/auth";
import axios from "axios";
import { baseApiUrl } from "../../constants";
import { ApiRequestParams } from "./types";
import { getToken } from "./helper";
import qs from "qs";

export const createCompany = async (company) => {
  const { idToken } = (await fetchAuthSession()).tokens ?? {};
  const token = `Bearer ${idToken?.toString()}`;

  const { data } = await axios.post(`${baseApiUrl}/companies`, company, {
    headers: {
      Authorization: token,
    },
  });
};

export const listCompanies = async (searchParams?) => {
  const queryString = qs.stringify(searchParams, { addQueryPrefix: true });

  const { data } = await axios.get(`${baseApiUrl}/companies${queryString}`, {
    headers: {
      Authorization: await getToken(),
    },
  });

  return data;
};

const useCompany = () => {
  const [companies, setCompanies] = useState([]);

  const listCompanies = async (params?: ApiRequestParams) => {
    const { idToken } = (await fetchAuthSession()).tokens ?? {};
    const token = `Bearer ${idToken?.toString()}`;

    console.log({ token });

    const { data } = await axios.get(`${baseApiUrl}/companies`, {
      headers: {
        Authorization: token,
      },
    });

    setCompanies(data);
    return data;
  };

  return { companies, listCompanies };
};

export default useCompany;
