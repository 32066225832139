import React from "react";
import { Table, Button, Dropdown, MenuProps } from "antd";
import NiceModal from "@ebay/nice-modal-react";
import { useQuery } from "@tanstack/react-query";
import TopicModal from "src/Modals/topic.modal";
import { useTranslation } from "react-i18next";
import { listTopics, deleteTopic } from "../../Services/Api/topic.api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/pro-light-svg-icons";

const Topics = () => {
  const topics = useQuery({
    queryKey: ["topics"],
    queryFn: listTopics,
  });

  const { t } = useTranslation();

  const items: MenuProps["items"] = [
    { key: "1", label: t("EDIT", "Edit") },
    { key: "2", label: t("DELETE", "Delete") },
  ];

  const showTopicModal = (topic?: any) => {
    // Show a modal with arguments passed to the component as props
    NiceModal.show(TopicModal, { topic }).then(() => topics.refetch());
  };

  const handleMenuClick = async (e, row) => {
    if (e.key === "1") {
      showTopicModal(row);
    }
    if (e.key === "2") {
      await deleteTopic(row._id);
      topics.refetch();
    }
  };

  const columns = [
    {
      title: t("NAME", "Name"),
      dataIndex: "name",
      key: "name",
      render: (name) => t(name),
    },
    {
      title: t("KEY", "Key"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("STATUS", "Status"),
      dataIndex: "status",
      key: "status",
      render: (status, row) => {
        return (
          <Dropdown
            menu={{
              items,
              onClick: (e) => {
                handleMenuClick(e, row);
              },
            }}
          >
            <a>
              <FontAwesomeIcon icon={faBars} size="xl" color="#5B9CD1" />
            </a>
          </Dropdown>
        );
      },
    },
  ];

  if (topics.error) {
    return <div>{topics.error.message}</div>;
  }

  return (
    <div style={{ width: "100%", padding: "20px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <h1>{t("TOPICS", "Topics")}</h1>
          <Button
            type="primary"
            onClick={() => {
              showTopicModal();
            }}
          >
            {t("CREATE_TOPIC", "Create Topic")}
          </Button>
        </div>
      </div>
      <Table
        dataSource={topics.data?.data}
        columns={columns}
        loading={topics.isPending}
      />
    </div>
  );
};

export default Topics;
