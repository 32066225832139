import React from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const Detail = ({ title, value }) => {
  return (
    <div style={{ marginBottom: "20px" }}>
      <h3 style={{ margin: 0, padding: 0 }}>{title}</h3>
      <p style={{ margin: 0, padding: 0 }}>{value}</p>
    </div>
  );
};

const getStatus = (status: string) => {
  switch (status) {
    case "published":
      return "Published";
    case "draft":
      return "Draft";
    default:
      return "";
  }
};
const EventDetails = ({ event }) => {
  const { t } = useTranslation();
  return (
    <div style={{ margin: "20px", marginLeft: "40px" }}>
      <h2>Details</h2>
      <Detail
        title={t("DATE", "Date")}
        value={dayjs(event.date).format("MMM Do, YYYY")}
      />

      <Detail
        title={t("TIME", "Time")}
        value={`${dayjs(event.startTime).format("hh:mm")} - ${dayjs(
          event.endTime
        ).format("hh:mm")}`}
      />

      <Detail
        title={t("EVENT_CATEGORY", "Event Category")}
        value={event.categories.map((c) => t(c.name))}
      />
      <Detail title={t("LEVEL", "Level")} value={event.level} />
      <Detail title={t("STATuS", "Status")} value={getStatus(event.status)} />
    </div>
  );
};

export default EventDetails;
