import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
// import Backend from "i18next-xhr-backend";
// import Backend from "i18next-locize-backend";
import Backend from "i18next-http-backend";
import axios from "axios";

const isProductionCode = process.env.NODE_ENV === "production";
const fallbackLanguage = "en";

const projectToken = "66634b50ad2e4eb6bdbb208c908bdedb"; // YOUR PROJECT TOKEN
const apiKey = "2213E03E83a4b58e4bc1eCAaeE27De23bf2A0d7494B82623"; // YOUR API KEY

const apiBaseUrl = "https://api.simplelocalize.io/api";
const cdnBaseUrl = "https://cdn.simplelocalize.io";
const environment = "_latest"; // or "_production"
const loadPath = `${cdnBaseUrl}/${projectToken}/${environment}/{{lng}}`;
const loadPathWithNamespaces = `${cdnBaseUrl}/${projectToken}/${environment}/{{lng}}/{{ns}}`;
const configuration = {
  headers: {
    "X-SimpleLocalize-Token": apiKey,
  },
};

const createTranslationKeys = async (requestBody) =>
  axios.post(
    `${apiBaseUrl}/v1/translation-keys/bulk`,
    requestBody,
    configuration
  );
const updateTranslations = async (requestBody) =>
  axios.patch(`${apiBaseUrl}/v2/translations/bulk`, requestBody, configuration);

const missing: any[] = [];
const saveMissing = async () => {
  if (missing.length === 0 || isProductionCode) {
    return;
  }
  console.info(`Saving ${missing.length} missing translation keys`);

  const translationKeys = missing.map((element) => ({
    key: element.translationKey,
    namespace: element.namespace,
  }));

  await createTranslationKeys({ translationKeys }).catch((error) =>
    console.error(`Error during creating translation keys: ${error}`)
  );

  const translations = missing.map((element) => ({
    key: element.translationKey,
    namespace: element.namespace,
    language: element.language,
    text: element.fallbackValue,
  }));
  await updateTranslations({ translations }).catch((error) =>
    console.error(`Error during updating translations: ${error}`)
  );
  missing.length = 0;
};

setInterval(async () => {
  console.log("Saving missing translations");
  console.log(missing);
  await saveMissing();
}, 30_000); // decreasing this value may lead to the API rate limit

const fallbackLng = ["en"];

i18n
  .use(Backend) // used to load data from othe directory
  .use(LanguageDetector) // detects the current language
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng, // default language
    // detection: {
    //   checkWhitelist: true,
    // },
    // ns: ["common", "settings", "events"],
    // defaultNS: "common",

    debug: false,
    interpolation: {
      escapeValue: false, // no need for react. it escapes by default
    },
    saveMissing: !isProductionCode,
    missingKeyHandler: async (
      languages,
      namespace,
      translationKey,
      fallbackValue
    ) => {
      console.debug(
        `[${namespace}][${translationKey}] not available in Translation Hosting`
      );
      missing.push({
        translationKey: translationKey,
        namespace: namespace ?? "",
        language: languages[0] ?? fallbackLanguage,
        fallbackValue: fallbackValue ?? "",
      });
    },
    // backend: {
    //   projectId: "39ef25c8-bd75-4bad-b813-74740a26db65",
    //   apiKey: "8db11d14-58cf-4ddc-aab8-858deb5b052b",
    //   referenceLng: "en",
    // },
  });

export default i18n;
