import React, { useState, useEffect, type ReactElement } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Amplify } from "aws-amplify";
import {
  getCurrentUser,
  fetchUserAttributes,
  fetchAuthSession,
} from "aws-amplify/auth";
import { ThemeProvider } from "@mui/material/styles";
import { Dashboard } from "./Dashboard";
import CssBaseline from "@mui/material/CssBaseline";
import type { PaletteMode } from "@mui/material";
import { Login, Register, ThanksForRegistering, Verify } from "./Auth";
import theme from "./theme";
import "@aws-amplify/ui-react/styles.css";
import Calendar from "./Views/Users/Calendar";
import Event from "./Views/Users/Event";
import Events from "./Views/Users/Events";
import EventAdmin from "./Views/OxyznAdmin/EventsList";
import EventsCompleted from "./Views/OxyznAdmin/EventsCompleted";
import ArticleList from "./Views/OxyznAdmin/ArticleAdmin";
import ArticleHr from "./Views/Admin/Articles";
import Article from "./Views/Users/Article";
import ExpertsAdmin from "./Views/OxyznAdmin/ExpertsAdmin";
import Experts from "./Views/Users/Experts";
import Expert from "./Views/Users/Expert";
import Topics from "./Views/OxyznAdmin/Topics";
import VideoAdmin from "./Views/OxyznAdmin/VideoAdmin";
import Categories from "./Views/OxyznAdmin/Categories";
import CompaniesList from "./Views/OxyznAdmin/Companies";
import Settings from "./Views/Users/Settings";
import UsersList from "./Views/Admin/Users";
import AdminUserList from "./Views/OxyznAdmin/Users";
import Articles from "./Views/Users/Articles";
import { awsExports } from "./aws-exports";
import { updateUser } from "./Services/Api/user.api";
import "react-dropzone-uploader/dist/styles.css";
import { Spin } from "antd";
import Callback from "./Auth/Callback";
import Videos from "./Views/Users/Videos";
import VideosHr from "./Views/Admin/Videos";
import { ConsoleLogger } from "./Utils/logger";
import { useLoggly } from "react-loggly-jslogger";
import Video from "./Views/Users/Video";
import PrivacyPolicy from "./Views/StaticPages/PrivacyPolicy";
import CompletedEventsHr from "./Views/Admin/EventsCompleted";
import Analytics from "./Views/Admin/Analytics";
import { userContext } from "./userContext";
import MyStuff from "./Views/Users/MyStuff";
import Company from "./Views/OxyznAdmin/Company";
import EventsList from "./Views/OxyznAdmin/EventsList";
import { Confirmation } from "./Auth/Confirmation";
import { ResetPassword } from "./Auth/ResetPassword";

// require("dayjs/locale/el");

let redirectSignIn = `${window.location}/callback`;

const config = {
  ...awsExports,
  oauth: { ...awsExports.oauth, redirectSignIn },
};

Amplify.configure(config);

console.log(`Env Vars: ${JSON.stringify(process.env)}`);

const replaceWindowConsole = ({ info, warn, error }) => {
  const logger = new ConsoleLogger({ level: "log" });

  var console = ((oldCons) => {
    return {
      ...oldCons,
      log: function (text) {
        logger.log(text);
        // Your code
      },
      info: function (text) {
        logger.info(text);
        info(text);
        // Your code
      },
      warn: function (text) {
        logger.warn(text);
        warn(text);
        // Your code
      },
      error: function (text) {
        logger.error(text);
        error(text);
        // Your code
      },
    };
  })(window.console);

  //Then redefine the old console
  window.console = console;
};

export function App() {
  const [mode, setMode] = useState<PaletteMode>("light");

  const { info, warn, error } = useLoggly();

  useEffect(() => {
    replaceWindowConsole({ info, warn, error });
  }, []);

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<any>();
  const [userRecord, setUserRecord] = useState<Record<string, any>>();

  const updateLastLoginAndFetchUser = async () => {
    const { data } = await updateUser({});
    console.log("********");
    console.log({ data });
    setUserRecord(data);
  };

  const getUser = async () => {
    let currentUser;
    try {
      currentUser = await getCurrentUser();
      const attributes = await fetchUserAttributes();
      const session = await fetchAuthSession();
      const groups = session.tokens?.idToken?.payload["cognito:groups"];
      console.log({ currentUser, attributes, session, groups });
      setUser(currentUser);
      updateLastLoginAndFetchUser();
      localStorage.setItem(
        "userInfo",
        JSON.stringify({
          ...currentUser,
          ...attributes,
          groups,
        })
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
    return currentUser;
  };

  useEffect(() => {
    getUser();
  }, []);

  const toggleMode = () => {
    setMode(mode === "light" ? "dark" : "light");
  };

  const UserRoute = ({ children }: { children: any }): ReactElement => {
    const info = JSON.parse(localStorage.getItem("userInfo") || "{}");

    if (!user) {
      console.log("Redirecting to login from user route");
      return <Navigate to="/login" />;
    } else {
      return children;
    }
  };

  const HrRoute = ({ children }: { children: any }): ReactElement => {
    const info = JSON.parse(localStorage.getItem("userInfo") || "{}");

    if (
      !user ||
      !info.groups ||
      (!info.groups.find((role) => role === "admin") &&
        !info.groups.find((role) => role === "god"))
    ) {
      console.log("Redirecting to login from user route");
      return <Navigate to="/login" />;
    } else {
      return children;
    }
  };

  const OxyznAdminRoute = ({ children }: { children: any }): ReactElement => {
    const info = JSON.parse(localStorage.getItem("userInfo") || "{}");

    if (!user || !info.groups || !info.groups.find((role) => role === "god")) {
      console.log("Redirecting to login from admin route");
      return <Navigate to="/login" />;
    } else {
      return children;
    }
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          height: "100%",
          alignItems: "center",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <ThemeProvider theme={theme(mode)}>
      <userContext.Provider
        value={{ user: userRecord || {}, refetch: updateLastLoginAndFetchUser }}
      >
        <CssBaseline />
        <Routes>
          <Route
            path="/"
            element={<Navigate to="/dashboard/calendar" replace />}
          />
          <Route path="login" element={<Login getUser={getUser} />} />
          <Route path="register" element={<Register />} />
          <Route path="forgot-password" element={<ResetPassword />} />
          <Route path="verify" element={<Verify />} />
          <Route
            path="confirmation/:confirmation_id"
            element={<Confirmation />}
          />
          <Route path="callback" element={<Callback />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="thanks-for-registering"
            element={<ThanksForRegistering />}
          />

          {/*
           *  Dashboard Routes, for signed in users
           */}
          <Route
            path="dashboard"
            element={
              <UserRoute>
                <Dashboard userRecord={userRecord} />
              </UserRoute>
            }
          >
            <Route
              index
              element={<Navigate to="/dashboard/calendar" replace />}
            />
            <Route path="calendar" element={<Calendar />} />
            <Route path="events" element={<Events />} />
            <Route path="events/:event_id" element={<Event />} />
            <Route path="articles" element={<Articles />} />
            <Route path="articles/:article_id" element={<Article />} />
            <Route path="videos" element={<Videos />} />
            <Route path="videos/:video_id" element={<Video />} />
            <Route path="settings" element={<Settings />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="experts" element={<Experts />} />
            <Route path="experts/:expert_id" element={<Expert />} />
            <Route path="me" element={<MyStuff />} />
          </Route>

          {/*
           *  HR Routes, for signed in admin users
           */}
          <Route
            path="dashboard/hr"
            element={
              <HrRoute>
                <Dashboard userRecord={userRecord} />
              </HrRoute>
            }
          >
            <Route
              index
              element={<Navigate to="/dashboard/hr/users" replace />}
            />
            <Route path="analytics" element={<Analytics user={userRecord} />} />
            <Route path="users" element={<UsersList user={userRecord} />} />
            <Route path="events" element={<EventsList user={userRecord} />} />
            <Route
              path="events-completed"
              element={<CompletedEventsHr user={userRecord} />}
            />
            <Route path="articles" element={<ArticleHr user={userRecord} />} />
            <Route path="videos" element={<VideosHr user={userRecord} />} />
          </Route>

          {/*
           *  Admin Routes, for Oxyzn Admins
           */}
          <Route
            path="dashboard/admin"
            element={
              <OxyznAdminRoute>
                <Dashboard userRecord={userRecord} />
              </OxyznAdminRoute>
            }
          >
            <Route
              index
              element={<Navigate to="/dashboard/admin/events" replace />}
            />
            <Route path="events" element={<EventsList />} />
            <Route path="events-completed" element={<EventsCompleted />} />
            <Route path="experts" element={<ExpertsAdmin />} />
            <Route path="users" element={<AdminUserList />} />
            <Route path="articles" element={<ArticleList />} />
            <Route path="topics" element={<Topics />} />
            <Route path="categories" element={<Categories />} />
            <Route path="companies" element={<CompaniesList />} />
            <Route path="companies/:company_id" element={<Company />} />
            <Route path="videos" element={<VideoAdmin />} />
          </Route>
        </Routes>
      </userContext.Provider>
    </ThemeProvider>
  );
}

export default App;
