import React from "react";
import { Card, Tag } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HeartOutlined, HeartFilled } from "@ant-design/icons";
import { userContext } from "src/userContext";
import { updateUser } from "src/Services/Api/user.api";
import { createMetric } from "src/Services/Api/metric";

const { Meta } = Card;

const ArticleCard = ({ article }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const {
    _id,
    image,
    locales,
    author,
    category = { name: "" },
    tags,
  } = article;

  const url = image.thumbnailUrl;

  const locale = locales[i18n.language.split("-")[0] || "en"] || locales.en;

  return (
    <userContext.Consumer>
      {({ user, refetch }: { user: any; refetch: any }) => {
        return (
          <Card
            hoverable
            style={{
              width: 240,
              textWrap: "pretty",
              margin: "10px",
            }}
            cover={
              <img
                alt="example"
                src={url}
                style={{ height: "140px", objectFit: "cover" }}
              />
            }
            onClick={() => {
              navigate(`/dashboard/articles/${_id}`);
            }}
          >
            <div
              style={{ position: "absolute", top: 0, left: 0, margin: "10px" }}
            >
              <Tag color="#fff" style={{ color: "#000", opacity: "0.8" }}>
                {t(category.name)}
              </Tag>

              {tags.map((tag) => {
                return (
                  <Tag color="#fff" style={{ color: "#000", opacity: "0.8" }}>
                    {t(tag.name)}
                  </Tag>
                );
              })}
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{
                  display: "-webkit-box",
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  height: "65px",
                  textOverflow: "ellipsis",
                }}
              >
                {locale.title}
              </div>

              <div>
                {user.favourites?.articles?.includes(_id)
                  ? [
                      <div
                        style={{
                          backgroundColor: "#fff",
                          borderRadius: "15px",
                          height: "30px",
                          width: "30px",
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                          opacity: "0.9",
                        }}
                      >
                        <HeartFilled
                          key="setting"
                          style={{ color: "#F89780", fontSize: "20px" }}
                          onClick={async (e) => {
                            e.stopPropagation();

                            await updateUser({
                              favourites: {
                                ...user.favourites,
                                articles: user.favourites.articles.filter(
                                  (id) => id !== _id
                                ),
                              },
                            });
                            await refetch();
                          }}
                        />
                      </div>,
                    ]
                  : [
                      <div
                        style={{
                          backgroundColor: "#fff",
                          borderRadius: "15px",
                          height: "30px",
                          width: "30px",
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                          opacity: "0.9",
                        }}
                      >
                        <HeartOutlined
                          key="setting"
                          style={{ fontSize: "20px" }}
                          onClick={async (e) => {
                            e.stopPropagation();

                            await createMetric({
                              type: "article.like",
                              asset: _id,
                            });

                            await updateUser({
                              favourites: {
                                ...user.favourites,
                                articles: [...user.favourites.articles, _id],
                              },
                            });
                            await refetch();
                          }}
                        />
                      </div>,
                    ]}
              </div>
            </div>
            <div style={{ color: "#999", marginTop: "5px" }}>
              {author.firstName || ""} {author.lastName || ""}
            </div>
          </Card>
        );
      }}
    </userContext.Consumer>
  );
};

export default ArticleCard;
