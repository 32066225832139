import React, { useState } from "react";
import { Button, Input } from "antd";
import { resetPassword } from "src/Services/Api/user.api";
import { useTranslation } from "react-i18next";

export const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const { t } = useTranslation();

  return (
    <div
      style={{
        marginTop: "80px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src="/logo.png" style={{ height: "60px", marginBottom: "20px" }} />
        <h1>{t("RESET_YOUR_PASSWORD", "Reset your Password")}</h1>
        <div style={{ marginTop: "20px" }}>{t("EMAIL", "Email")}:</div>
        <Input
          style={{ width: "400px" }}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
      </div>
      <Button
        type="primary"
        style={{ marginTop: "20px" }}
        onClick={async () => {
          await resetPassword({
            email,
          });
        }}
      >
        {t("SEND_PASSWORD_RESET_EMAIL", "Send Password Reset Email")}
      </Button>
    </div>
  );
};
