import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  Checkbox,
  Popover,
  Button,
  Collapse,
} from "antd";
import NiceModal, { useModal } from "@ebay/nice-modal-react";

import { object, string } from "yup";
import { IVideo } from "src/Types/Database.types";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { QueryResult } from "src/Types/QueryResult";
import { listExperts } from "src/Services/Api/expert.api";
import { listTopics } from "src/Services/Api/topic.api";
import { createVideo, updateVideo } from "src/Services/Api/video.api";
import ReactQuill from "react-quill";
import { locales } from "src/constants";

const { Search } = Input;

// Define Yup schema for event validation
let videoSchema = object({
  youtubeUrl: string(),
  language: string(),
});

export default NiceModal.create(({ video }: { video?: IVideo }) => {
  // Use a hook to manage the modal state
  const modal = useModal();
  const [loading, setLoading] = useState(false);
  const [loadingVideo, setLoadingVideo] = useState(false);

  const [selectedLocale, setSelectedLocale] = useState();
  const [selectedLocales, setSelectedLocales] = useState(["en"]);

  const { t } = useTranslation();

  useEffect(() => {
    if (video) {
      setSelectedLocales(Object.keys(video.locales).map((l) => l));
    }
  }, []);

  const [form] = Form.useForm();
  const experts: QueryResult = useQuery({
    queryKey: ["experts"],
    queryFn: () => listExperts({ limit: 1000 }),
  });

  const topics: QueryResult = useQuery({
    queryKey: ["topics"],
    queryFn: () => listTopics({ limit: 1000 }),
  });

  const initialLocales = {};
  if (video) {
    Object.keys(video?.locales).forEach((l) => {
      initialLocales[`locales.${l}.title`] = video.locales[l].title;
      initialLocales[`locales.${l}.description`] = video.locales[l].description;
    });
  }

  const handleSelectLocale = (e) => {
    setSelectedLocale(e);
  };

  return (
    <Modal
      title={video ? "Edit Video" : "Create Video"}
      onOk={async () => {
        try {
          const result = await form.validateFields();
        } catch {
          // Ignore error
        }

        const newEvent = form.getFieldsValue();
        setLoading(true);

        Object.keys(newEvent).forEach((key) => {
          if (key.split(".").length) {
            const [, locale, att] = key.split(".");

            if (!locale) {
              return;
            }

            if (!newEvent.locales) {
              newEvent.locales = {};
            }

            let thisLocale = newEvent.locales[locale];

            if (!thisLocale) {
              newEvent.locales[locale] = {};
            }

            newEvent.locales[locale][att] = newEvent[key];
          }

          delete newEvent[key];
        });

        let e;
        try {
          const validated = await videoSchema.validate(newEvent);

          if (!video) {
            e = await createVideo(validated);
          } else {
            e = await updateVideo({ ...validated, _id: video._id });
          }

          modal.resolve(e);
          modal.hide();
        } catch (err) {
          console.error(err);
          setLoading(false);
        }
      }}
      confirmLoading={loading}
      open={modal.visible}
      onCancel={() => modal.hide()}
      afterClose={() => modal.remove()}
    >
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{
          ...initialLocales,
          isFeatured: video?.isFeatured,
          topics: video?.topics.map((c) => c._id),
          youtubeUrl: video?.youtubeUrl,
          language: video?.language,
        }}
        autoComplete="off"
      >
        <div style={{ margin: "20px" }}>
          {selectedLocales.map((locale) => {
            return (
              <Collapse
                bordered={false}
                defaultActiveKey={["1"]}
                items={[
                  {
                    key: "1",
                    label: locale,
                    children: (
                      <>
                        <Form.Item
                          label="Title"
                          name={`locales.${locale}.title`}
                          rules={[{ required: true, message: "Required" }]}
                        >
                          <Input
                            onChange={(e) => {
                              console.log({ e });
                            }}
                            style={{ borderRadius: "5px" }}
                          />
                        </Form.Item>
                        <Form.Item
                          label="Description"
                          name={`locales.${locale}.description`}
                          rules={[{ required: true, message: "Required" }]}
                        >
                          <ReactQuill
                            onChange={(e) => {
                              console.log({ e });
                            }}
                            style={{ borderRadius: "5px" }}
                          />
                        </Form.Item>
                      </>
                    ),
                  },
                ]}
              />
            );
          })}
        </div>

        <Popover
          content={
            <div>
              <Select
                style={{ minWidth: "200px" }}
                options={locales.filter(
                  (l) => !selectedLocales.includes(l.value)
                )}
                onChange={handleSelectLocale}
              />

              <Button
                style={{ margin: "10px" }}
                type="primary"
                onClick={() => {
                  if (selectedLocale) {
                    setSelectedLocales([...selectedLocales, selectedLocale!]);
                  }
                }}
              >
                Add
              </Button>
              {/* <a onClick={hide}>Close</a> */}
            </div>
          }
          title="Click title"
          trigger="click"
          // open={clicked}
          // onOpenChange={handleClickChange}
        >
          <Button style={{ marginBottom: "20px", marginLeft: "20px" }}>
            Add Locale
          </Button>
        </Popover>

        <Form.Item<IVideo>
          label="Video URL"
          name="youtubeUrl"
          rules={[{ required: true, message: "Required" }]}
        >
          <Search
            loading={loadingVideo}
            onChange={async ({ target }) => {
              // Ks-_Mh1QhMc
              if (target.value.length === 11) {
                setLoadingVideo(true);
                const videoId = target.value;
                const result = await axios.get(
                  `https://www.googleapis.com/youtube/v3/videos?part=snippet%2CcontentDetails%2Cstatistics&id=${videoId}&key=AIzaSyCMNnEImKRfj9Ap0l7t5aD_WyCt3F6dLQ4`
                );
                console.log(result);
                setLoading(false);
              }
            }}
          />
        </Form.Item>

        <Form.Item<IVideo>
          label="Topics"
          name="topics"
          rules={[{ required: true, message: "Required" }]}
        >
          <Select
            loading={topics.isPending}
            mode="multiple"
            placeholder="Select an Topic"
            optionFilterProp="children"
            defaultValue={video?.topics.map((topic) => {
              return t(topic.name);
            })}
            options={
              !topics.isPending
                ? topics.data.data.map((topic) => {
                    return {
                      value: topic._id,
                      label: t(topic.name),
                    };
                  })
                : []
            }
          />
        </Form.Item>

        <Form.Item<IVideo>
          label="Is Featured?"
          name="isFeatured"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>

        <Form.Item<IVideo>
          label="Language"
          name="language"
          rules={[{ required: true, message: "Required" }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
});
