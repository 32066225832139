import React, { useState, type ReactElement } from "react";
import { Input, Button, Alert, Typography } from "antd";
import Grid from "@mui/material/Grid";
import { signUp } from "aws-amplify/auth";
import useCompanyHook from "src/Services/Api/company.api";
import useUserHook from "src/Services/Api/user.api";
import { useNavigate } from "react-router-dom";
import Copyright from "../Components/Copyright";

export const Register = (): ReactElement => {
  const [error, setError] = useState<string>();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [company, setCompany] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [loading, setLoading] = useState(false);

  const { createUser } = useUserHook();
  const { listCompanies } = useCompanyHook();

  const navigate = useNavigate();

  const handleSignUp = async () => {
    try {
      // Check if company name exists
      // const existingConpanies = await listCompanies({
      //   where: {
      //     name: company,
      //   },
      // });

      // if (existingConpanies.length) {
      //   setError("Company Name Already Exists");
      //   return;
      // }

      const { isSignUpComplete, userId, nextStep } = await signUp({
        username: email,
        password,
        options: {
          userAttributes: {
            email,
            given_name: firstName,
            family_name: lastName,
          },

          // optional
          autoSignIn: true, // or SignInOptions e.g { authFlowType: "USER_SRP_AUTH" }
        },
      });

      if (userId) {
        createUser(userId, company);

        setLoading(false);
        navigate("/thanks-for-registering");

        console.log({ isSignUpComplete, userId, nextStep });
      }
    } catch (err: any) {
      console.log(err);
      setError(err.message);
      setLoading(false);
    }
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      {/* <CssBaseline /> */}

      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: "url(https://source.unsplash.com/random?wallpapers)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5}>
        <div
          style={{
            marginTop: "80px",
            marginLeft: "40px",
            marginRight: "40px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src="/logo.png"
            style={{ height: "60px", marginBottom: "20px" }}
          />

          <Typography.Title level={5}>Register</Typography.Title>
          {error && (
            <Alert message={error} type="error" style={{ marginTop: "10px" }} />
          )}

          <div>
            <Input
              required
              id="company"
              placeholder="Company Name"
              name="company"
              autoComplete="company"
              autoFocus
              onChange={(e) => {
                setError(undefined);
                setCompany(e.target.value);
              }}
              style={{ marginTop: "20px" }}
            />
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Input
                required
                id="firstname"
                placeholder="First Name"
                name="firstname"
                autoComplete="First Name"
                autoFocus
                onChange={(e) => {
                  setError(undefined);
                  setFirstName(e.target.value);
                }}
                style={{ marginTop: "20px", marginRight: "20px" }}
              />
              <Input
                required
                id="lastname"
                placeholder="Last Name"
                name="lastname"
                autoComplete="Last Name"
                autoFocus
                onChange={(e) => {
                  setError(undefined);
                  setLastName(e.target.value);
                }}
                style={{ marginTop: "20px" }}
              />
            </div>
            <Input
              required
              id="email"
              placeholder="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(e) => {
                setError(undefined);
                setEmail(e.target.value);
              }}
              style={{ marginTop: "20px" }}
            />
            <Input
              required
              name="password"
              placeholder="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => {
                setError(undefined);
                setPassword(e.target.value);
              }}
              style={{ marginTop: "20px" }}
            />
            <div style={{ margin: "20px" }}>
              <Button
                type="primary"
                loading={loading}
                onClick={() => {
                  if (!email) {
                    setError("Please enter your username");
                  } else if (!password) {
                    setError("Please enter your password");
                  } else {
                    setLoading(true);
                    handleSignUp();
                  }
                }}
                style={{ float: "right" }}
              >
                Log In
              </Button>
            </div>

            <Grid container>
              <Grid item xs>
                <Button type="link" href="/login" style={{ fontSize: "12px" }}>
                  {"Already have an account? Log in"}
                </Button>
              </Grid>
              <Grid item></Grid>
            </Grid>
            <Copyright sx={{ mt: 5 }} />
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default Register;
