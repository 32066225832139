import React from "react";
import { Typography } from "antd";

const { Title } = Typography;

export const ThanksForRegistering = () => {
  return (
    <div
      style={{
        display: "flex",
        margin: "100px",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <img src="/logo.png" style={{ height: "60px", marginBottom: "20px" }} />

      <Title level={1}>Thanks for Registering</Title>
      <p>Please verify your email address</p>
    </div>
  );
};
