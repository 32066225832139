import React, { useEffect, useState } from "react";
import { Collapse, Flex, Checkbox, Spin, Select } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { listResource } from "src/Services/Api/resource.list";

const Filter = ({ title, resource, onChange }) => {
  const [selected, setSelected] = useState<string[]>([]);

  const { data } = useQuery({
    queryKey: [resource],
    queryFn: listResource,
  });

  useEffect(() => {
    onChange(selected);
  }, [selected]);

  const { t } = useTranslation();

  return (
    <Select
      size="large"
      mode="multiple"
      style={{
        minWidth: "100%",
      }}
      onChange={(data) => {
        setSelected(data);
      }}
    >
      {data?.data
        .filter((thing) => !selected.includes(thing._id))
        .map((thing) => {
          return (
            <Select.Option value={thing._id}>{t(thing.name)}</Select.Option>
          );
        })}
    </Select>
  );

  return (
    <Collapse
      style={{ marginTop: "20px" }}
      items={[
        {
          key: "1",
          label: title,
          children: (
            <Flex vertical>
              {data ? (
                data?.data.map((thing) => {
                  return (
                    <Checkbox
                      style={{ margin: "5px" }}
                      value={thing._id}
                      onChange={({ target }) => {
                        if (target.checked) {
                          setSelected([...selected, target.value]);
                        } else {
                          const index = selected.indexOf(target.value);

                          if (index !== -1) {
                            selected.splice(index, 1);
                            setSelected([...selected]);
                          }
                        }
                      }}
                    >
                      {t(thing.name)}
                    </Checkbox>
                  );
                })
              ) : (
                <Spin></Spin>
              )}
            </Flex>
          ),
        },
      ]}
      defaultActiveKey={["1"]}
    />
  );
};

export default Filter;
