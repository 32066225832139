import React from "react";
import { Select } from "antd";
import { useTranslation } from "react-i18next";

const AgePicker = (props) => {
  const { t } = useTranslation();
  return (
    <Select {...props} style={{ width: "100%" }} size="large">
      <Select.Option value="under25">{t("UNDER_25", "under 25")}</Select.Option>
      <Select.Option value="under30">25 - 29</Select.Option>
      <Select.Option value="under35">30 - 34</Select.Option>
      <Select.Option value="under40">35 - 39</Select.Option>
      <Select.Option value="under45">40 - 44</Select.Option>
      <Select.Option value="under50">
        {t("45_AND_ABOVE", "45 and above")}
      </Select.Option>
      <Select.Option value="nondisclosed">
        {t("PREFER_NOT_TO_SAY", "Prefer not to say")}
      </Select.Option>
    </Select>
  );
};

export default AgePicker;
