export const baseApiUrl =
  // process.env.NODE_ENV === "production"
  // ?
  "https://api.oxyzn.net";
// :
// "https://api-test.oxyzn.net";

export const locales = [
  { label: "English", value: "en" },
  { label: "French", value: "fr" },
  { label: "German", value: "de" },
  { label: "Italian", value: "it" },
  { label: "Greek", value: "el" },
  { label: "Bulgarian", value: "br" },
];
