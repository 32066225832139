import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd'
import { BrowserRouter } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { LogglyProvider } from 'react-loggly-jslogger';

import "./il8n";
import './index.css';


const queryClient = new QueryClient()

const logglyOptions = {
  logglyKey: '52f9febf-defd-44e8-a4bd-69db2ca61d14',
  sendConsoleErrors: true,
  tag: 'oxyzn-web-app',
}

console.log('hello from index')

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <Authenticator.Provider>
  <BrowserRouter>

    <ConfigProvider theme={{
      token: {
        // Seed Token
        colorPrimary: '#598BBC',
        borderRadius: 6,

        // Alias Token
        colorBgContainer: '#fff',
        dangerItemColor: '#fff'
      },
      "components": {
        "Input": {
          "algorithm": true,
          "paddingBlock": 10
        }
      }
    }}>
      <QueryClientProvider client={queryClient}>
        <NiceModal.Provider>
          <LogglyProvider options={logglyOptions}>
            <App />
          </LogglyProvider>
        </NiceModal.Provider>
      </QueryClientProvider>
    </ConfigProvider>

  </BrowserRouter >
  // </Authenticator.Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
