import React from "react";
import { Table } from "antd";
import { useQuery } from "@tanstack/react-query";
import { listUsers } from "../../Services/Api/user.api";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const columns = (t) => [
  {
    title: t("EMAIL", "Email"),
    dataIndex: "email",
    key: "email",
    render: (email: string, row: any) => {
      return <Link to={`/admin/users/${row._id}`}>{email}</Link>;
    },
  },
  {
    title: t("COMPANY", "Company"),
    dataIndex: "company",
    key: "company",
    render: (company: { name: string }) => {
      return company.name;
    },
  },
  {
    title: t("REGISTERED_EVENTS", "Registered Events"),
    dataIndex: "registeredEvents",
    key: "registeredEvents",
    render: (registeredEvents) => {
      return <>{registeredEvents.length}</>;
    },
  },
  {
    title: t("LAST_LOGIN", "Last Login"),
    dataIndex: "lastLogin",
    key: "lastLogin",
    render: (lastLogin: Date) => {
      return dayjs(lastLogin).fromNow();
    },
  },
];

const AdminUsers = ({ user }) => {
  const { t } = useTranslation();
  const users = useQuery({
    queryKey: ["users"],
    queryFn: () => listUsers({ where: { company: user.company._id } }),
  });

  //   const showUserModal = () => {
  //     // Show a modal with arguments passed to the component as props
  //     NiceModal.show(UserModal, { name: "Nate" });
  //   };

  if (users.error) {
    return <div>Error</div>;
  }

  return (
    <div style={{ width: "100%", padding: "20px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <h1>{t("USERS", "Users")}</h1>
      </div>
      <Table
        dataSource={users.data?.data}
        columns={columns(t)}
        loading={users.isPending}
      />
    </div>
  );
};

export default AdminUsers;
