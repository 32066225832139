import React from "react";
import { Modal, Button } from "antd";
import dayjs from "dayjs";
import qs from "qs";
import ical, { ICalCalendarMethod } from "ical-generator";

const generateGoogleLink = (event) => {
  if (!event) {
    return "";
  }

  const calendarEvent = {
    text: event.title,
    dates: `${dayjs(event.startTime).format("YYYYMMDDTHHmmSS")}Z/${dayjs(
      event.endTime
    ).format("YYYYMMDDTHHmmSS")}Z`,
    details: `${event.meetingLink} - ${event.description}`,
  };

  const url = "https://calendar.google.com/calendar/u/0/r/eventedit";
  const queryString = qs.stringify(calendarEvent, {
    addQueryPrefix: true,
    encode: false,
  });

  return `${url}${queryString}`;
};

const generateICalEvent = (event) => {
  if (!event) {
    return;
  }
  console.log(`Generating ical event for ${event.name}`);

  const calendar = ical({ name: event.title });
  calendar.method(ICalCalendarMethod.REQUEST);

  const startTime = event.startTime;
  const endTime = event.endTime;
  calendar.createEvent({
    start: startTime,
    end: endTime,
    summary: event.title,
    description: event.description,

    url: event.meetingLink,
  });

  // Open/Save link in Modern Browsers
  window.open(
    encodeURI("data:text/calendar;charset=utf8," + calendar.toString())
  );
};

const EventRegistrationModal = ({ event, isModalOpen, handleOk }) => {
  return (
    <Modal
      title="Thanks for Registering"
      open={isModalOpen}
      onOk={handleOk}
      footer={(_, { OkBtn, CancelBtn }) => (
        <>
          <Button onClick={handleOk}>Close</Button>
        </>
      )}
    >
      <p>
        Thanks for registering for this event, in a few minutes you will recieve
        and email with an invite to this event.
      </p>
      <p>
        You can add this event to your calendar now by clicking the calendar
        links below
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <a target="_blank" href={generateGoogleLink(event)}>
          <Button type="primary" style={{ margin: "20px" }}>
            {" "}
            Add to Google Calendar
          </Button>
        </a>

        <Button
          type="primary"
          style={{ margin: "20px" }}
          onClick={() => generateICalEvent(event)}
        >
          Add to iCal / Outlook
        </Button>
      </div>
    </Modal>
  );
};

export default EventRegistrationModal;
