import React, { useState } from "react";
import { Modal, Form, Input } from "antd";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { createTopic, updateTopic } from "src/Services/Api/topic.api";

interface ITopic {
  name: string;
}

export default NiceModal.create(({ topic }: { topic?: any }) => {
  // Use a hook to manage the modal state
  const modal = useModal();
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  console.log({ form });
  return (
    <Modal
      title={topic ? "Edit Topic" : "Create Topic"}
      onOk={async () => {
        const newTopic = form.getFieldsValue();

        console.log({ newTopic });

        setLoading(true);

        let created;
        try {
          if (!topic) {
            created = await createTopic(newTopic);
          } else {
            created = await updateTopic({ ...newTopic, _id: topic._id });
          }

          modal.resolve(created);
          modal.hide();
        } catch (err) {
          console.log(err);
          setLoading(false);
        }
      }}
      confirmLoading={loading}
      open={modal.visible}
      onCancel={() => modal.hide()}
      afterClose={() => modal.remove()}
    >
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ name: topic?.name }}
        autoComplete="off"
      >
        <Form.Item<ITopic>
          label="Topic Key"
          name="name"
          rules={[{ required: true, message: "Required" }]}
        >
          <Input />
        </Form.Item>
        <p>Note: you will need to add the translations for this key</p>
      </Form>
    </Modal>
  );
});
