import React from "react";
import { faClock } from "@fortawesome/pro-thin-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

const Timestamp = ({ startTime, endTime, color = "#888" }) => {
  return (
    <span
      style={{
        display: "flex",
        marginTop: "5px",
        alignItems: "center",
      }}
    >
      <FontAwesomeIcon icon={faClock} color={color} />
      <div
        style={{
          fontWeight: "500",
          color: color,
          marginLeft: "5px",
          fontSize: "12px",
        }}
      >
        {dayjs(startTime).local().format("HH:mm")} -{" "}
        {dayjs(endTime).local().format("HH:mm")}
      </div>
    </span>
  );
};

export default Timestamp;
