import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getResource } from "src/Services/Api/resource.get";
import { Skeleton } from "antd";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createMetric } from "src/Services/Api/metric";
import { useTranslation } from "react-i18next";
import { Image } from "src/Components/Image/Image";

const Paragraph = ({ entity, loading }) => {
  const { i18n } = useTranslation();
  if (loading) {
    return <Skeleton active />;
  }

  const { locales, author } = entity.data.data;
  const locale = locales[i18n.language.split("-")[0] || "en"] || locales.en;
  return (
    <div style={{ margin: "20px" }}>
      <h1 style={{ marginTop: "20px" }}>{locale.title}</h1>
      <div style={{ display: "flex", flexDirection: "row", margin: "6px" }}>
        <div style={{ marginRight: "5px" }}>by</div>
        <a
          href={`/dashboard/experts/${author._id}`}
        >{`${author.firstName} ${author.lastName}`}</a>
      </div>
      <div dangerouslySetInnerHTML={{ __html: locale.article }}></div>
    </div>
  );
};
const Article = () => {
  const { i18n } = useTranslation();
  const { article_id: id } = useParams();

  const fetchArticle = async ({ queryKey }) => {
    const [key, { id }] = queryKey;

    return getResource(
      { queryKey },
      {
        populate: "image author",
      }
    );
  };

  const article = useQuery({
    queryKey: ["content", { id }],
    queryFn: fetchArticle,
  });

  const mutation = useMutation({
    mutationFn: () => {
      return createMetric({
        type: "article.read",
        asset: id,
      });
    },
  });

  useEffect(() => {
    mutation.mutate();
  }, []);

  if (article.error) {
    return (
      <div>
        Return to <a href="/dashboard/articles">Articles</a>
      </div>
    );
  }

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ maxWidth: "800px", margin: "20px", width: "100%" }}>
        <Image entity={article} loading={article.isPending} />
        <Paragraph entity={article} loading={article.isPending} />
      </div>
    </div>
  );
};
export default Article;
