import type { PaletteMode, Theme } from "@mui/material";
import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = (mode: PaletteMode): Theme => {
  return createTheme({
    palette: {
      background: {
        default: "rgba(0,0,0,0)",
      },
      mode,
      primary: {
        main: "#3FC6F1",
      },
      secondary: {
        main: "#f50057",
      },
    },
    typography: {
      fontWeightLight: 200,
      fontWeightRegular: 400,
      fontWeightMedium: 600,
      fontWeightBold: 900,
      fontSize: 12,
    },
  });
};

export default theme;
