export const randomIntFromInterval = (min, max) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const shuffleArray = async (array: string[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = await randomIntFromInterval(0, i);
    [array[i], array[j]] = [array[j], array[i]];
  }
};

export const defaultOptions = {
  length: 12,
  uppercase: true,
  minUppercase: 3,
  lowercase: true,
  minLowercase: 3,
  number: true,
  minNumber: 3,
  special: true,
  minSpecial: 1,
};

export const generatePassword = async (o: any): Promise<string> => {
  // overload defaults with given options

  const minLength: number =
    o.minUppercase + o.minLowercase + o.minNumber + o.minSpecial;
  if (o.length < minLength) {
    o.length = minLength;
  }

  const positions: string[] = [];
  if (o.lowercase && o.minLowercase > 0) {
    for (let i = 0; i < o.minLowercase; i++) {
      positions.push("l");
    }
  }
  if (o.uppercase && o.minUppercase > 0) {
    for (let i = 0; i < o.minUppercase; i++) {
      positions.push("u");
    }
  }
  if (o.number && o.minNumber > 0) {
    for (let i = 0; i < o.minNumber; i++) {
      positions.push("n");
    }
  }
  if (o.special && o.minSpecial > 0) {
    for (let i = 0; i < o.minSpecial; i++) {
      positions.push("s");
    }
  }
  while (positions.length < o.length) {
    positions.push("a");
  }

  // shuffle
  await shuffleArray(positions);

  // build out the char sets
  let allCharSet = "";

  let lowercaseCharSet = "abcdefghijkmnopqrstuvwxyz";
  if (o.ambiguous) {
    lowercaseCharSet += "l";
  }
  if (o.lowercase) {
    allCharSet += lowercaseCharSet;
  }

  let uppercaseCharSet = "ABCDEFGHJKLMNPQRSTUVWXYZ";
  if (o.ambiguous) {
    uppercaseCharSet += "IO";
  }
  if (o.uppercase) {
    allCharSet += uppercaseCharSet;
  }

  let numberCharSet = "23456789";
  if (o.ambiguous) {
    numberCharSet += "01";
  }
  if (o.number) {
    allCharSet += numberCharSet;
  }

  const specialCharSet = "!@#$%^&*";
  if (o.special) {
    allCharSet += specialCharSet;
  }

  let password = "";
  for (let i = 0; i < o.length; i++) {
    let positionChars: string = "";
    switch (positions[i]) {
      case "l":
        positionChars = lowercaseCharSet;
        break;
      case "u":
        positionChars = uppercaseCharSet;
        break;
      case "n":
        positionChars = numberCharSet;
        break;
      case "s":
        positionChars = specialCharSet;
        break;
      case "a":
        positionChars = allCharSet;
        break;
      default:
        break;
    }
    const randomCharIndex = await randomIntFromInterval(
      0,
      positionChars.length - 1
    );
    password += positionChars.charAt(randomCharIndex);
  }

  return password;
};
