import React, { type ReactElement } from "react";
import { Typography, Button } from "antd";

const Copyright = (props: any): ReactElement => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Button
        type="link"
        color="primary"
        style={{ margin: 0, padding: 0 }}
        href="https://oxyzn.net/"
      >
        Oxyzn
      </Button>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

export default Copyright;
