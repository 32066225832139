import { fetchAuthSession } from "aws-amplify/auth";
import axios from "axios";
import { baseApiUrl } from "../../constants";

const useImage = () => {
  const createImage = async () => {
    const { idToken } = (await fetchAuthSession()).tokens ?? {};
    const token = `Bearer ${idToken?.toString()}`;

    const { data } = await axios.post(
      `${baseApiUrl}/images`,
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return data;
  };

  const updateImage = async ({ id, payload }) => {
    const { idToken } = (await fetchAuthSession()).tokens ?? {};
    const token = `Bearer ${idToken?.toString()}`;

    const { data } = await axios.patch(
      `${baseApiUrl}/images/${id}`,
      { ...payload },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return data;
  };

  return { createImage, updateImage };
};

export default useImage;
