import React from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Menu, Button, Grid } from "antd";
import SidebarHeader from "./SidebarHeader";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faUsers,
  faUserTie,
  faCalendarDays,
  faBookOpenCover,
  faCog,
  faList,
  faList12,
  faCalendarCheck,
  faVideo,
  faBuildings,
  faChartSimple,
  faGrid,
} from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";

const { useBreakpoint } = Grid;
const { Sider } = Layout;

export const godSection = (t) => {
  return {
    key: "god",
    label: t("OXYZN_ADMIN"),
    type: "group",
    children: [
      {
        key: "admin/events",
        label: t("EVENTS"),
        icon: (
          <FontAwesomeIcon style={{ height: "18px" }} icon={faCalendarDays} />
        ),
      },
      {
        key: "admin/events-completed",
        label: t("COMPLETED_EVENTS"),
        icon: (
          <FontAwesomeIcon style={{ height: "18px" }} icon={faCalendarCheck} />
        ),
      },
      {
        key: "admin/articles",
        label: t("ARTICLES"),
        icon: (
          <FontAwesomeIcon style={{ height: "18px" }} icon={faBookOpenCover} />
        ),
      },
      {
        key: "admin/videos",
        label: t("VIDEOS"),
        icon: <FontAwesomeIcon style={{ height: "18px" }} icon={faVideo} />,
      },
      {
        key: "admin/experts",
        label: t("EXPERTS"),
        icon: <FontAwesomeIcon style={{ height: "18px" }} icon={faUserTie} />,
      },
      {
        key: "admin/topics",
        label: t("TOPICS"),
        icon: <FontAwesomeIcon style={{ height: "18px" }} icon={faList} />,
      },
      {
        key: "admin/categories",
        label: t("CATEGORIES"),
        icon: <FontAwesomeIcon style={{ height: "18px" }} icon={faList12} />,
      },
      {
        key: "admin/companies",
        label: t("COMPANIES"),
        icon: <FontAwesomeIcon style={{ height: "18px" }} icon={faBuildings} />,
      },
      {
        key: "admin/users",
        label: t("USERS"),
        icon: <FontAwesomeIcon style={{ height: "18px" }} icon={faUsers} />,
      },
    ],
  };
};

export const adminSection = (t) => {
  return {
    key: "admin",
    label: t("HR_ADMIN"),
    type: "group",
    children: [
      {
        key: "hr/analytics",
        label: t("ANALYTICS"),
        icon: (
          <FontAwesomeIcon style={{ height: "18px" }} icon={faChartSimple} />
        ),
      },
      {
        key: "hr/users",
        label: t("USERS"),
        icon: <FontAwesomeIcon style={{ height: "18px" }} icon={faUsers} />,
      },
      {
        key: "hr/events",
        label: t("EVENTS"),
        icon: (
          <FontAwesomeIcon style={{ height: "18px" }} icon={faCalendarDays} />
        ),
      },
      {
        key: "hr/events-completed",
        label: t("COMPLETED_EVENTS"),
        icon: (
          <FontAwesomeIcon style={{ height: "18px" }} icon={faCalendarCheck} />
        ),
      },
      {
        key: "hr/articles",
        label: t("ARTICLEs"),
        icon: (
          <FontAwesomeIcon style={{ height: "18px" }} icon={faBookOpenCover} />
        ),
      },
      {
        key: "hr/videos",
        label: t("VIDEOS"),
        icon: <FontAwesomeIcon style={{ height: "18px" }} icon={faVideo} />,
      },
    ],
  };
};

export const generalSection = (t) => {
  return {
    key: "general",
    label: t("GENERAL"),
    type: "group",
    children: [
      {
        key: "calendar",
        label: t("CALENDAR"),
        icon: <FontAwesomeIcon style={{ height: "18px" }} icon={faCalendar} />,
      },
      {
        key: "me",
        label: t("MY_STUFF"),
        icon: <FontAwesomeIcon style={{ height: "18px" }} icon={faGrid} />,
      },
      {
        key: "articles",
        label: t("ARTICLES"),
        icon: (
          <FontAwesomeIcon style={{ height: "18px" }} icon={faBookOpenCover} />
        ),
      },
      {
        key: "videos",
        label: t("VIDEO_LIBRARY"),
        icon: <FontAwesomeIcon style={{ height: "18px" }} icon={faVideo} />,
      },
      {
        key: "events",
        label: t("ALL_EVENTS"),
        icon: (
          <FontAwesomeIcon style={{ height: "18px" }} icon={faCalendarDays} />
        ),
      },
      {
        key: "experts",
        label: t("EXPERTS"),
        icon: <FontAwesomeIcon style={{ height: "18px" }} icon={faUsers} />,
      },
      {
        key: "settings",
        label: t("SETTINGS"),

        icon: <FontAwesomeIcon style={{ height: "18px" }} icon={faCog} />,
      },
    ],
  };
};
export const AppSidebar = ({ collapsed, setCollapsed }) => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { xs } = useBreakpoint();

  const info = JSON.parse(localStorage.getItem("userInfo") || "{}");

  let items = [generalSection(t)];

  if (info.groups && info.groups.find((role) => role === "admin")) {
    items = [adminSection(t), generalSection(t)];
  }

  if (info.groups && info.groups.find((role) => role === "god")) {
    items = [godSection(t), adminSection(t), generalSection(t)];
  }

  return (
    <Sider
      breakpoint="lg"
      collapsedWidth={xs ? 0 : 80}
      onBreakpoint={(broken) => {}}
      onCollapse={(collapsed, type) => {
        setCollapsed(collapsed);
      }}
      trigger={null}
      collapsible
      collapsed={collapsed || xs}
      style={{
        // background: "#003366",
        zIndex: "110",
        border: "0px",
        borderRight: "1px",
        borderStyle: "solid",
        borderColor: "#ddd",
        overflow: "hidden",
      }}
      width={"260px"}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#fff",
        }}
      >
        <Button
          type="text"
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={() => setCollapsed(!collapsed)}
          style={{
            fontSize: "16px",
            width: 64,
            height: 64,
          }}
        />
        <SidebarHeader sidebarOpen={!collapsed} />
      </div>
      <Menu
        mode="inline"
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={["sub1"]}
        style={{
          height: "100%",
          borderRight: 0,
          fontWeight: "500",
          color: "#6A9ACC",
          zIndex: "100000",
          // fontSize:'20px'
        }}
        items={items}
        onClick={(e) => {
          navigate(`/dashboard/${e.key}`);
        }}
      />
    </Sider>
  );
};
