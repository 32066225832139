import axios from "axios";
import { baseApiUrl } from "../../constants";
import { getToken } from "./helper";
import qs from "qs";

export const createMetric = async (metric) => {
  const { data } = await axios.post(`${baseApiUrl}/metrics`, metric, {
    headers: {
      Authorization: await getToken(),
    },
  });

  return data;
};

export const listMetrics = async (searchParams?: any) => {
  const queryString = qs.stringify(searchParams, { addQueryPrefix: true });

  const { data } = await axios.get(`${baseApiUrl}/metrics${queryString}`, {
    headers: {
      Authorization: await getToken(),
    },
  });

  return data;
};
