import { useState } from "react";
import { fetchAuthSession } from "aws-amplify/auth";
import axios from "axios";
import { baseApiUrl } from "../../constants";

const useReview = () => {
  const [loading, setLoading] = useState(false);
  const [cache, setCache] = useState();

  const create = async (inputData) => {
    setLoading(true);
    const { idToken } = (await fetchAuthSession()).tokens ?? {};
    const token = `Bearer ${idToken?.toString()}`;

    const { data } = await axios.post(`${baseApiUrl}/reviews`, inputData, {
      headers: {
        Authorization: token,
      },
    });
    setLoading(false);
    return data;
  };

  return { create, loading };
};

export default useReview;
