import React from "react";
import { Table, Button, Dropdown, MenuProps } from "antd";
import NiceModal from "@ebay/nice-modal-react";
import CategoryModal from "src/Modals/category.modal";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import {
  deleteCategory,
  listCategories,
} from "../../Services/Api/category.api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/pro-light-svg-icons";

const Categories = () => {
  const categories = useQuery({
    queryKey: ["categories"],
    queryFn: listCategories,
  });

  const { t } = useTranslation();

  const items: MenuProps["items"] = [
    { key: "1", label: t("EDIT", "Edit") },
    { key: "2", label: t("DELETE", "Delete") },
  ];

  const showCategoryModal = (category?) => {
    // Show a modal with arguments passed to the component as props
    NiceModal.show(CategoryModal, { category }).then(() =>
      categories.refetch()
    );
  };

  const handleMenuClick = async (e, row) => {
    if (e.key === "1") {
      showCategoryModal(row);
    }
    if (e.key === "2") {
      await deleteCategory(row._id);
      categories.refetch();
    }
  };

  const columns = [
    {
      title: t("NAME", "Name"),
      dataIndex: "name",
      key: "name",
      render: (name) => t(name),
    },
    {
      title: t("KEY", "Key"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("STATUS", "Status"),
      dataIndex: "status",
      key: "status",
      render: (status, row) => {
        return (
          <Dropdown
            menu={{
              items,
              onClick: (e) => {
                handleMenuClick(e, row);
              },
            }}
          >
            <a>
              <FontAwesomeIcon icon={faBars} size="xl" color="#5B9CD1" />
            </a>
          </Dropdown>
        );
      },
    },
  ];

  if (categories.error) {
    return <div>{categories.error.message}</div>;
  }

  return (
    <div style={{ width: "100%", padding: "20px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <h1>{t("CATEGORIES", "Categories")}</h1>
          <Button
            type="primary"
            onClick={() => {
              showCategoryModal();
            }}
          >
            {t("CREATE_CATEGORY", "Create Category")}
          </Button>
        </div>
      </div>
      <Table
        dataSource={categories.data?.data}
        columns={columns}
        loading={categories.isPending}
      />
    </div>
  );
};

export default Categories;
