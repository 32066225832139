import React from "react";

interface SidebarHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  sidebarOpen: boolean;
}

const SidebarHeader: React.FC<SidebarHeaderProps> = ({ sidebarOpen }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "70px",
        // marginTop: "20px",
        // marginBottom: "20px",
      }}
    >
      {sidebarOpen ? (
        <img src="/logo.png" style={{ height: "40px", margin: "10px" }} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default SidebarHeader;
