import { useQuery } from "@tanstack/react-query";
import React from "react";
import { listMetrics } from "src/Services/Api/metric";
import { LineChart } from "@mui/x-charts/LineChart";
import { PieChart } from "@mui/x-charts/PieChart";
import { Card, Col, Divider, Rate, Row, Spin } from "antd";
import CountUp from "react-countup";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const NumberCard = ({ count, title, loading = false }) => {
  if (loading) {
    return <Spin />;
  }
  return (
    <Card bordered={true} style={{ margin: "20px", minWidth: "300px" }}>
      <h2 style={{ fontWeight: "100" }}>{title}</h2>
      <div style={{ fontWeight: 600, fontSize: "44px", color: "#598BBC" }}>
        <CountUp start={0} end={count} duration={2} />
      </div>
    </Card>
  );
};
const ChartCard = ({ data, title }) => {
  return (
    <Card bordered={true} style={{ margin: "20px" }}>
      <h2 style={{ fontWeight: "100" }}>{title}</h2>
      <LineChart
        sx={{
          "& .MuiLineElement-root": {
            strokeDasharray: "10 5",
            strokeWidth: 4,
          },
          "& .MuiAreaElement-series-Germany": {
            fill: "url('#myGradient')",
          },
        }}
        xAxis={[
          {
            dataKey: "date",
            scaleType: "time",
            tickMinStep: 3600 * 1000 * 24, // min step: 24h
          },
        ]}
        series={[
          {
            dataKey: "value",
            showMark: false,
            area: true,
            color: "#7BBCE5",
          },
        ]}
        dataset={data}
        //   width={500}
        height={300}
        width={500}
      />
      <defs>
        <linearGradient id="myGradient" gradientTransform="rotate(90)">
          <stop offset="5%" stopColor="gold" />
          <stop offset="95%" stopColor="red" />
        </linearGradient>
      </defs>
    </Card>
  );
};

const PieCard = ({ data, title }) => {
  return (
    <Card bordered={true} style={{ margin: "20px" }}>
      <h2 style={{ fontWeight: "100" }}>{title}</h2>
      <PieChart
        series={[
          {
            data,
            innerRadius: 30,
            outerRadius: 100,
            paddingAngle: 5,
            cornerRadius: 5,
            startAngle: -90,
            endAngle: 270,
            cx: 150,
            cy: 150,
          },
        ]}
        width={390}
        height={260}
      />
    </Card>
  );
};

const Analytics = ({ user }) => {
  const { t } = useTranslation();

  const fetchMetrics = async ({ queryKey }) => {
    const [_key] = queryKey;

    return listMetrics({
      where: {
        companyId: { $eq: user.company._id },
        createdAt: { $gt: dayjs().subtract(1, "week").format("YYYY-MM-DD") },
      },
      limit: 0,
    });
  };

  const fetchRegisteredUsers = async ({ queryKey }) => {
    const [_key] = queryKey;

    return listMetrics({
      where: {
        type: { $eq: "user.login" },
        companyId: { $eq: user.company._id },
      },
      limit: 0,
    });
  };

  const fetchActiveUsers = async ({ queryKey }) => {
    const [_key] = queryKey;

    return listMetrics({
      where: {
        type: { $eq: "user.login" },
        companyId: { $eq: user.company._id },
        createdAt: { $gt: dayjs().subtract(1, "week").format("YYYY-MM-DD") },
      },
      limit: 0,
    });
  };

  const fetchArticlesRead = async ({ queryKey }) => {
    const [_key] = queryKey;

    return listMetrics({
      where: {
        type: { $eq: "article.read" },
        companyId: { $eq: user.company._id },
        createdAt: { $gt: dayjs().subtract(1, "week").format("YYYY-MM-DD") },
      },
      limit: 0,
    });
  };

  const fetchArticlesLiked = async ({ queryKey }) => {
    const [_key] = queryKey;

    return listMetrics({
      where: {
        type: { $eq: "article.like" },
        companyId: { $eq: user.company._id },
        createdAt: { $gt: dayjs().subtract(1, "week").format("YYYY-MM-DD") },
      },
      limit: 0,
    });
  };

  // const metrics = useQuery({
  //   queryKey: ["metrics"],
  //   queryFn: fetchMetrics,
  // });

  const registeredUsers = useQuery({
    queryKey: ["metrics"],
    queryFn: fetchRegisteredUsers,
  });

  const activeUsers = useQuery({
    queryKey: ["metrics", "user.login"],
    queryFn: fetchActiveUsers,
  });

  const articlesRead = useQuery({
    queryKey: ["metrics", "article.read"],
    queryFn: fetchArticlesRead,
  });

  const articlesLiked = useQuery({
    queryKey: ["metrics", "article.like"],
    queryFn: fetchArticlesLiked,
  });

  // if (metrics.error) {
  //   return <div>Error</div>;
  // }

  // if (!metrics.data?.data) {
  //   return (
  //     <div style={{ display: "flex", justifyContent: "center" }}>
  //       <Spin size="large" style={{ margin: "100px" }} />
  //     </div>
  //   );
  // }

  // const articlesRead = metrics.data?.data.filter(
  //   (i) => i.type === "article.view"
  // );
  // const articlesReadCount = articlesRead.length;

  // const videosWatched = metrics.data?.data.filter(
  //   (i) => i.type === "video.view"
  // );
  // const videosWatchedCount = videosWatched.length;

  // const eventsViewed = metrics.data?.data.filter(
  //   (i) => i.type === "event.view"
  // );
  // const eventsViewedCount = eventsViewed.length;

  // const eventsRegistered = metrics.data?.data.filter(
  //   (i) => i.type === "event.register"
  // );
  // const eventsRegisteredCount = eventsRegistered.length;

  // const articlesReadData: any[] = [];
  // const videosWatchedData: any[] = [];
  // const eventsViewedData: any[] = [];
  // const eventsRegisteredData: any[] = [];

  // [6, 5, 4, 3, 2, 1, 0].forEach((offset) => {
  //   const day = dayjs().startOf("day").subtract(offset, "days");

  //   articlesReadData.push({ date: day.toDate(), value: 0 });
  //   videosWatchedData.push({ date: day.toDate(), value: 0 });
  //   eventsViewedData.push({ date: day.toDate(), value: 0 });
  //   eventsRegisteredData.push({ date: day.toDate(), value: 0 });

  //   metrics.data?.data.forEach((a) => {
  //     if (dayjs(a.createdAt).isSame(day, "day")) {
  //       if (a.type === "article.view")
  //         articlesReadData.find(
  //           (d) => dayjs(d.date).format() === day.format()
  //         ).value += 1;
  //       if (a.type === "video.view")
  //         videosWatchedData.find(
  //           (d) => dayjs(d.date).format() === day.format()
  //         ).value += 1;
  //       if (a.type === "event.view")
  //         eventsViewedData.find(
  //           (d) => dayjs(d.date).format() === day.format()
  //         ).value += 1;
  //       if (a.type === "event.register")
  //         eventsRegisteredData.find(
  //           (d) => dayjs(d.date).format() === day.format()
  //         ).value += 1;
  //     }
  //   });
  // });

  return (
    <div style={{ padding: "20px" }}>
      <div style={{ display: "flex" }}>
        <div style={{ padding: "20px" }}>
          <NumberCard
            loading={registeredUsers.isPending}
            count={registeredUsers.data?.count}
            title={t("TOTAL_USERS", "Total Users")}
          />
        </div>
      </div>

      <h1>{t("THIS_WEEK", "This Week")}</h1>
      <Divider />
      <h2 style={{ margin: "20px" }}>{t("USERS", "Users")}</h2>
      <div style={{ display: "flex" }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <NumberCard
            loading={activeUsers.isPending}
            count={
              activeUsers.data?.data.reduce((acc, cur) => {
                console.log({ acc, cur });
                if (!acc.includes(cur.asset)) {
                  acc.push(cur.asset);
                }
                return acc;
              }, []).length
            }
            title={t("ACTIVE_USERS", "Active Users")}
          />
        </div>
      </div>

      <Divider />

      <div style={{ display: "flex" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <NumberCard
            loading={articlesRead.isPending}
            count={articlesRead.data?.count}
            title={t("ARTICLES_READ", "Articles Read")}
          />
          <NumberCard
            loading={articlesLiked.isPending}
            count={articlesLiked.data?.count}
            title={t("ARTICLES_LIKED", "Articles Liked")}
          />
        </div>
        {/* <PieCard
          title={t("ARTICLES_BY_TOPIC", "Articles by Topic")}
          data={[
            { id: 0, value: 30, label: "Anti Stress", color: "#3FC6F1" },
            { id: 1, value: 15, label: "Energy", color: "#4679BA" },
            { id: 2, value: 20, label: "Gut Health", color: "#16406A" },
          ]}
        />
        <ChartCard
          data={articlesRead.data.count}
          title={t("ARTICLES_READ", "Articles Read")}
        /> */}
      </div>

      <Divider />

      {/* <div style={{ display: "flex" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <NumberCard
            count={20}
            title={t("VIDEOS_WATCHED", "Videos Watched")}
          />
          <NumberCard count={8} title={t("VIDEOS_LIKED", "Videos Liked")} />
        </div>
        <PieCard
          title={t("VIDEOS_BY_TOPIC", "Videos by Topic")}
          data={[
            { id: 0, value: 20, label: "Productivity", color: "#3FC6F1" },
            { id: 1, value: 15, label: "Energy", color: "#4679BA" },
            { id: 2, value: 20, label: "Relaxation", color: "#16406A" },
          ]}
        />
        <ChartCard
          data={videosWatchedData}
          title={t("VIDEOS_WATCHED", "Videos Watched")}
        />
      </div>
      <Divider />
      <div style={{ display: "flex" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <NumberCard
            count={43}
            title={t("NUMBER_OF_REGISTRATIONS", "Number of Registrations")}
          />
          <Card bordered={true} style={{ margin: "20px" }}>
            <h2 style={{ fontWeight: "100" }}>
              {t("AVERAGE_RATING", "Average Rating")}
            </h2>
            <div
              style={{ fontWeight: 600, fontSize: "44px", color: "#598BBC" }}
            >
              <Rate allowHalf defaultValue={4.8} />
            </div>
          </Card>
        </div>
        <PieCard
          title={t("EVENTS_BY_TOPIC", "Events by Topic")}
          data={[
            { id: 0, value: 20, label: "Creativity", color: "#3FC6F1" },
            { id: 1, value: 15, label: "Anti Stress", color: "#4679BA" },
            { id: 2, value: 20, label: "Relaxation", color: "#16406A" },
            { id: 2, value: 7, label: "Gut Health", color: "#16406A" },
          ]}
        />
        <ChartCard
          data={eventsRegisteredData}
          title={t("NUMBER_OF_REGISTRATIONS", "Number of Registrations")}
        />
      </div> */}
    </div>
  );
};

export default Analytics;
