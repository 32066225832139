import React from "react";
import { Input, Select, Form, Button } from "antd";
import { useTranslation } from "react-i18next";
import GenderPicker from "src/Components/Selects/GenderPicker";
import AgePicker from "src/Components/Selects/AgePicker";
import LanguagePicker from "src/Components/Selects/LanguagePicker";
import { userContext, IUser } from "src/userContext";
import TimezonePicker from "src/Components/Selects/TimezonePicker";

const Settings = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  console.log("HELLO");

  return (
    <div style={{ margin: "20px", padding: "20px" }}>
      <h1 style={{ marginBottom: "50px" }}> {t("SETTINGS")}</h1>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      ></div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      ></div>
      <div style={{ marginBottom: "70px" }}>
        <userContext.Consumer>
          {({ user }: { user: any }) => {
            if (!user || JSON.stringify(user) === "{}") {
              return <div></div>;
            }

            return (
              <Form
                form={form}
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ width: "100%" }}
                autoComplete="off"
              >
                <Form.Item label={t("COMPANY", "Company")} name="company">
                  <Input disabled defaultValue={user.company?.name} />
                </Form.Item>
                <Form.Item
                  label={t("PREFERED_LANGUAGE", "Preferred Language")}
                  name="language"
                >
                  <LanguagePicker />
                </Form.Item>
                <Form.Item label={t("TIMEZONE", "Timezone")} name="timezone">
                  <TimezonePicker />
                </Form.Item>
                <Form.Item label={t("GENDER", "Gender")} name="gender">
                  <GenderPicker defaultValue={user.preferences.gender} />
                </Form.Item>
                <Form.Item label={t("AGE", "Age")} name="age">
                  <AgePicker defaultValue={user.preferences.age} />
                </Form.Item>
              </Form>
            );
          }}
        </userContext.Consumer>
        <Button style={{ float: "right" }} type="primary">
          {t("SAVE_SETTINGS", "Save Settings")}
        </Button>
      </div>
    </div>
  );
};

export default Settings;
