import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faBars } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Tag } from "antd";
import type { MenuProps, Breakpoint } from "antd";
import dayjs from "dayjs";
import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { useTranslation } from "react-i18next";

export const columns = (handleMenuClick, t, i18n) => {
  const items: MenuProps["items"] = [
    // { key: "1", label: "Duplicate", disabled: true },
    { key: "2", label: t("EDIT", "Edit") },
    { key: "3", label: t("DELETE", "Delete") },
  ];

  return [
    {
      title: t("IMAGE", "Image"),
      dataIndex: "image",
      key: "image",
      render: (image) => {
        return (
          <img
            style={{
              width: "60px",
              margin: "5px",
              borderRadius: "5px",
            }}
            src={image.thumbnailUrl}
          />
        );
      },
    },
    {
      title: t("TITLE", "Title"),
      dataIndex: "locales",
      key: "title",
      render: (locales, row) => {
        const locale =
          locales[i18n.language.split("-")[0] || "en"] || locales.en;
        const { isFeatured } = row;
        return (
          <div>
            {isFeatured ? (
              <FontAwesomeIcon
                icon={faStar}
                size="lg"
                color="orange"
                style={{ width: "40px", margin: "0px" }}
              />
            ) : (
              <div></div>
            )}
            <Link to={`/dashboard/events/${row._id}`}>{locale.title}</Link>
          </div>
        );
      },
    },
    {
      title: t("AUTHOR", "Author"),
      dataIndex: "author",
      key: "author",
      render: (author) => {
        return <div>{`${author.firstName} ${author.lastName}`}</div>;
      },
    },
    {
      title: t("DATE", "Date"),
      dataIndex: "date",
      key: "date",
      width: "1000",
      render: (cell, row) => {
        return (
          <div>
            <div>{dayjs(cell).format("MMM Do, YYYY")}</div>
            <div style={{ color: "#999" }}>
              {dayjs(row.startTime).format("HH:mm")} -
              {dayjs(row.endTime).format("HH:mm")}
            </div>
          </div>
        );
      },
    },
    {
      title: t("LEVEL", "Level"),
      dataIndex: "level",
      key: "level",
      responsive: ["xl"] as Breakpoint[],
    },
    {
      title: t("NUMBER_OF_ATTENDEES", "Num Attendees"),
      dataIndex: "attendees",
      key: "attendees",
      responsive: ["xl"] as Breakpoint[],
      render: (attendees, row) => {
        return <>{_.uniq(attendees).length}</>;
      },
    },
    {
      title: t("STATUS", "Status"),
      dataIndex: "status",
      key: "status",
      responsive: ["xl"] as Breakpoint[],
      render: (status) => {
        if (status === "draft") {
          return <Tag color="orange">{t("DRAFT", "Draft")}</Tag>;
        }
        if (status === "archived") {
          return <Tag color="red">{t("ARCHIVED", "Archived")}</Tag>;
        }
        return <Tag color="blue">{t("PUBLISHED", "Published")}</Tag>;
      },
    },
    {
      title: "",
      dataIndex: "status",
      key: "status",
      render: (status, row) => {
        return (
          <Dropdown
            menu={{
              items,
              onClick: (e) => {
                handleMenuClick(e, row);
              },
            }}
          >
            <a>
              <FontAwesomeIcon icon={faBars} size="xl" color="#5B9CD1" />
            </a>
          </Dropdown>
        );
      },
    },
  ];
};
