import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getResource } from "src/Services/Api/resource.get";
import { Spin } from "antd";
import { useMutation, useQuery } from "@tanstack/react-query";
import YoutubeEmbed from "src/Components/YoutubeEmbed";
import { createMetric } from "src/Services/Api/metric";
import { useTranslation } from "react-i18next";

const Video = () => {
  const { i18n } = useTranslation();
  const { video_id: id } = useParams();

  const fetchVideo = async ({ queryKey }) => {
    const [key, { id }] = queryKey;

    return getResource(
      { queryKey },
      {
        populate: ["image"],
      }
    );
  };

  const video = useQuery({
    queryKey: ["content", { id }],
    queryFn: fetchVideo,
  });

  const mutation = useMutation({
    mutationFn: () => {
      return createMetric({
        type: "video.read",
        asset: id,
      });
    },
  });

  useEffect(() => {
    mutation.mutate();
  }, []);

  if (video.error) {
    return (
      <div>
        Return to <a href="/dashboard/videos">Videos</a>
      </div>
    );
  }
  if (video.isPending) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Spin />
      </div>
    );
  }

  const {
    meta: { youtubeUrl },
    locales,
  } = video.data!.data;
  const locale = locales[i18n.language.split("-")[0] || "en"] || locales.en;
  const vidId = youtubeUrl.split("v=")[1];
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: "20px",
      }}
    >
      <h1
        style={{
          fontWeight: 600,
          color: "#A7C7E7",
          margin: "20px",
          marginLeft: "40px",
        }}
      >
        {locale.title}
      </h1>
      <div style={{ margin: "40px" }}>
        <YoutubeEmbed embedId={vidId} />
      </div>
      <p>{locale.description}</p>
    </div>
  );
};
export default Video;
