import { fetchAuthSession } from "aws-amplify/auth";
import axios from "axios";
import { baseApiUrl } from "../../constants";
import qs from "qs";
import { getToken } from "./helper";

export const listEvents = async (searchParams?: any) => {
  const queryString = qs.stringify(searchParams, { addQueryPrefix: true });

  const { data } = await axios.get(`${baseApiUrl}/events${queryString}`, {
    headers: {
      Authorization: await getToken(),
    },
  });

  return data;
};

export const deleteEvent = async (eventId: string) => {
  await axios.delete(`${baseApiUrl}/events/${eventId}`, {
    headers: {
      Authorization: await getToken(),
    },
  });
};

const useEvent = () => {
  const list = async (searchParams?: any) => {
    const queryString = qs.stringify(searchParams, { addQueryPrefix: true });
    const token = await getToken();

    try {
      const { data } = await axios.get(`${baseApiUrl}/events${queryString}`, {
        headers: {
          Authorization: token,
        },
      });

      return data;
    } catch (err) {
      return {};
    }
  };

  const create = async (event) => {
    const { idToken } = (await fetchAuthSession()).tokens ?? {};
    const token = `Bearer ${idToken?.toString()}`;

    const { data } = await axios.post(`${baseApiUrl}/events`, event, {
      headers: {
        Authorization: token,
      },
    });

    return data;
  };

  const update = async (event) => {
    const { idToken } = (await fetchAuthSession()).tokens ?? {};
    const token = `Bearer ${idToken?.toString()}`;

    const { data } = await axios.patch(
      `${baseApiUrl}/events/${event._id}`,
      event,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return data;
  };

  const get = async (eventId: string, searchParams?: any) => {
    const queryString = qs.stringify(searchParams, { addQueryPrefix: true });

    const { idToken } = (await fetchAuthSession()).tokens ?? {};
    const token = `Bearer ${idToken?.toString()}`;

    const { data } = await axios.get(
      `${baseApiUrl}/events/${eventId}${queryString}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return data;
  };

  return { list, create, update, get, loading: false };
};

export default useEvent;
