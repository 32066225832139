import axios from "axios";
import { baseApiUrl } from "../../constants";
import qs from "qs";
import { getToken } from "./helper";

export const listResource = async ({ queryKey }, searchParams?) => {
  const [resource] = queryKey;
  const queryString = qs.stringify(searchParams, { addQueryPrefix: true });
  const { data } = await axios.get(`${baseApiUrl}/${resource}${queryString}`, {
    headers: {
      Authorization: await getToken(),
    },
  });

  return data;
};
