import React, { useState } from "react";
import { Col, Flex, Row, Spin } from "antd";
import { useQuery } from "@tanstack/react-query";
import Event from "src/Components/EventCard";
import SkeletonCard from "src/Components/ArticleCard/SkeletonCard";
import dayjs from "dayjs";
import { getUser } from "src/Services/Api/user.api";
import { Link } from "react-router-dom";
import ArticleCard from "src/Components/ArticleCard";
import VideoCard from "src/Components/VideoCard/VideoCard";
import { useTranslation } from "react-i18next";

const MyStuff = () => {
  const { t } = useTranslation();

  const [search, setSearch] = useState<string>();

  const fetchUser = async () => {
    return getUser({
      id: "me",
      searchParams: {
        populate: [
          {
            path: "favourites.articles",
            populate: "image categories topics",
          },
          {
            path: "favourites.videos",
            populate: "topics",
          },
          {
            path: "registeredEvents",
            populate: "image categories topics",
          },
        ],
      },
    });
  };

  const user = useQuery({
    queryKey: ["users"],
    queryFn: fetchUser,
  });

  if (user.isPending) {
    return (
      <div>
        <Spin></Spin>
      </div>
    );
  }

  if (user.isError) {
    return <div>Something went wrong</div>;
  }

  const { favourites, registeredEvents } = user.data.data;

  if (!favourites || !registeredEvents) {
    return <div>Loading</div>;
  }
  const { articles, videos } = favourites;

  console.log({ articles, videos });

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        flexWrap: "wrap",
      }}
    >
      <h2
        style={{
          fontWeight: 900,
          color: "#000",
          margin: "20px",
          marginLeft: "40px",
        }}
      >
        {t("EVENTS_YOUR_ATTENDING", "Events you're attending")}
      </h2>

      <Row>
        <Col md={24} style={{ padding: "20px" }}>
          <Flex wrap="wrap">
            {user.isPending &&
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((key) => {
                return <SkeletonCard />;
              })}
            {registeredEvents.filter((e) => dayjs(e.date).isAfter(dayjs()))
              .length ? (
              registeredEvents
                .filter((e) => dayjs(e.date).isAfter(dayjs()))
                .map((event) => {
                  return <Event event={event} />;
                })
            ) : (
              <div style={{ margin: "20px" }}>
                {t("NO_EVENTS_FOUND", "No Events Found. Register to a")}{" "}
                <Link to="/dashboard/events">
                  {t("NEW_EVENT", "new event")}
                </Link>{" "}
                {t("TO_SEE_IT_HERE", "to see it here")}
              </div>
            )}
          </Flex>
        </Col>
      </Row>
      <h2
        style={{
          fontWeight: 900,
          color: "#000",
          margin: "20px",
          marginLeft: "40px",
        }}
      >
        {t("EVENTS_YOUVE_ATTENDED", "Events you've attended")}
      </h2>
      <Row>
        <Col md={24} style={{ padding: "20px" }}>
          <Flex wrap="wrap">
            {user.isPending &&
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((key) => {
                return <SkeletonCard />;
              })}
            {registeredEvents.filter((e) => dayjs(e.date).isBefore(dayjs()))
              .length ? (
              registeredEvents
                .filter((e) => dayjs(e.date).isBefore(dayjs()))
                .map((event) => {
                  return <Event event={event} />;
                })
            ) : (
              <div style={{ margin: "20px" }}>
                {t(
                  "NO_EVENTS_FOUND",
                  "No Events Found. Looks like you've not attended any events yet"
                )}
              </div>
            )}
          </Flex>
        </Col>
      </Row>
      <h2
        style={{
          fontWeight: 900,
          color: "#000",
          margin: "20px",
          marginLeft: "40px",
        }}
      >
        {t("YOUR_FAVOURITE_ARTICLES", "Your Favourite Articles")}
      </h2>
      <Row>
        <Col md={24} style={{ padding: "20px" }}>
          <Flex wrap="wrap">
            {user.isPending &&
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((key) => {
                return <SkeletonCard />;
              })}
            {articles.length ? (
              articles.map((article) => {
                return <ArticleCard article={article} />;
              })
            ) : (
              <div style={{ margin: "20px" }}>
                {t(
                  "NO_ARTICLES_FOUND",
                  "No Articles Found. Check out new articles"
                )}{" "}
                <Link to="/dashboard/articles">{t("HERE", "here")}</Link>
              </div>
            )}
          </Flex>
        </Col>
      </Row>
      <h2
        style={{
          fontWeight: 900,
          color: "#000",
          margin: "20px",
          marginLeft: "40px",
        }}
      >
        {t("YOUR_FAVOURITE_VIDEOS", "Your Favourite Videos")}
      </h2>
      <Row>
        <Col md={24} style={{ padding: "20px" }}>
          <Flex wrap="wrap">
            {user.isPending &&
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((key) => {
                return <SkeletonCard />;
              })}
            {videos.length ? (
              videos.map((video) => {
                return <VideoCard video={video} />;
              })
            ) : (
              <div style={{ margin: "20px" }}>
                {t(
                  "NO_VIDEOS_FOUND",
                  "No Videos Found. Check out new articles"
                )}{" "}
                <Link to="/dashboard/videos">{t("HERE", "here")}</Link>
              </div>
            )}
          </Flex>
        </Col>
      </Row>
    </div>
  );
};

export default MyStuff;
