import React, { useState } from "react";
import { Rate, Form, Input, Button } from "antd";
import useReview from "src/Services/Api/review.api";
import { useTranslation } from "react-i18next";

const Detail = ({ title, value }) => {
  return (
    <div style={{ marginBottom: "20px" }}>
      <h3 style={{ margin: 0, padding: 0 }}>{title}</h3>
      <p style={{ margin: 0, padding: 0 }}>{value}</p>
    </div>
  );
};
const EventRate = ({ event }) => {
  const { t } = useTranslation();
  const { create, loading } = useReview();
  const [rating, setRating] = useState(3);
  const [review, setReview] = useState("");
  const [hasReviewd, setHasReviewed] = useState(event._hasReview);

  const handleSubmit = async () => {
    const { userId } = JSON.parse(localStorage.getItem("userInfo") || "{}");

    await create({
      eventId: event._id,
      rating,
      review,
      userId,
    });

    setHasReviewed(true);
  };

  if (hasReviewd) {
    return (
      <div style={{ margin: "20px", marginLeft: "40px", marginTop: "100px" }}>
        <h2>
          {t(
            "THANKS_FOR_REVIEWING_THIS_EVENT",
            "Thanks for reviewing this event"
          )}
        </h2>
      </div>
    );
  }

  return (
    <div style={{ margin: "20px", marginLeft: "40px", marginTop: "100px" }}>
      <h2>{t("DID_YOU_LIKE_THIS_EVENT", "Did you like this event?")}</h2>
      <Rate
        defaultValue={rating}
        style={{ marginTop: "10px", marginBottom: "10px" }}
        onChange={(r) => {
          setRating(r);
        }}
      />

      <Input.TextArea
        placeholder={t("WRITE_A_REVIEW", "Write a review")}
        onChange={(e) => {
          setReview(e.target.value);
        }}
      />

      <Button
        loading={loading}
        type="primary"
        style={{ margin: "20px", float: "right" }}
        onClick={handleSubmit}
      >
        {t("SUBMIT_REVIEW", "Submit Review")}
      </Button>
    </div>
  );
};

export default EventRate;
