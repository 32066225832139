import React from "react";
import { Select } from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

require("dayjs/locale/fr");
require("dayjs/locale/el");

dayjs.extend(utc);
dayjs.extend(timezone);

const LanguagePicker = (props) => {
  const { t, i18n } = useTranslation();
  const locale = dayjs.locale();
  console.log({ locale });

  const changeLanguage = (e) => {
    console.log(`Changing language to ${e}`);
    i18n.changeLanguage(e);
    dayjs.locale(e);
  };

  return (
    <Select
      {...props}
      style={{ width: "100%" }}
      size="large"
      onChange={changeLanguage}
      value={locale}
    >
      <Select.Option value="en">{t("ENGLISH", "English")}</Select.Option>
      <Select.Option value="fr">{t("FRENCH", "French")}</Select.Option>
      <Select.Option value="el">{t("GREEK", "Greek")}</Select.Option>
      <Select.Option value="it">{t("ITALIAN", "Italian")}</Select.Option>
      <Select.Option value="de">{t("GERMAN", "German")}</Select.Option>
      <Select.Option value="br">{t("BULGARIAN", "Bulgarian")}</Select.Option>
    </Select>
  );
};

export default LanguagePicker;
