import React from "react";
import { Modal, Button } from "antd";
import NiceModal, { useModal } from "@ebay/nice-modal-react";

export default NiceModal.create(() => {
  // Use a hook to manage the modal state
  const modal = useModal();

  const handleNext = () => {
    modal.resolve();
    modal.hide();
  };

  return (
    <Modal
      title={""}
      onOk={async () => {
        modal.resolve();
        modal.hide();
      }}
      onCancel={() => {
        modal.resolve();
        modal.hide();
      }}
      open={modal.visible}
      afterClose={() => modal.remove()}
      footer={[
        <Button key={1} type="primary" onClick={handleNext}>
          Next
        </Button>,
      ]}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1 style={{ marginBottom: "20px" }}>Thanks for Joining!</h1>
        <img
          style={{ height: "200px", width: "220px" }}
          src="/images/rocket.jpg"
        />
        <p style={{ color: "000", fontWeight: 300 }}>
          Welcome to your journey towards a better you.
        </p>
      </div>
    </Modal>
  );
});
