import { Skeleton } from "antd";
import React from "react";

export const Image = ({ entity, loading }) => {
  if (loading) {
    return (
      <Skeleton.Image
        active
        style={{
          height: "360px",
          width: "100%",
        }}
      />
    );
  }

  console.log({ entity });
  const { image } = entity.data.data;
  console.log({ image });
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "end",
        backgroundImage: `url(${image.url})`,
        height: "360px",
        width: "100%",
        backgroundRepeat: "no-repeat",
        // @ts-ignore
        backgroundColor: (t): string | number =>
          t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: "20px",
      }}
    ></div>
  );
};
