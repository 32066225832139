import React from "react";
import { Card, Tag, Avatar } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const { Meta } = Card;

const ExpertCard = ({ expert }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const { _id, image, firstName, lastName, locales } = expert;

  const locale = locales[i18n.language.split("-")[0] || "en"] || locales.en;

  const url = image.url;
  return (
    <Card
      hoverable
      style={{ width: 240, textWrap: "pretty", margin: "10px" }}
      // cover={<Avatar src={url} style={{height:'200px'}} />}
      onClick={() => {
        navigate(`/dashboard/experts/${_id}`);
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          height: "202px",
        }}
      >
        <Avatar
          src={url}
          style={{
            height: "202px",
            width: "202px",
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <div
          style={{
            marginLeft: "10px",
            marginRight: "10px",
            marginTop: "20px",
            fontSize: "18px",
            fontWeight: "500",
          }}
        >{`${firstName || ""} ${lastName || ""}`}</div>
      </div>
      <div style={{ color: "#999", margin: "10px", textAlign: "center" }}>
        {locale.expertise}
      </div>
    </Card>
  );
};

export default ExpertCard;
