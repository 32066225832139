import axios from "axios";
import { baseApiUrl } from "../../constants";
import { getToken } from "./helper";
import qs from "qs";

export const getResource = async ({ queryKey }, searchParams?) => {
  const [resource, { id }] = queryKey;
  const queryString = qs.stringify(searchParams, { addQueryPrefix: true });

  const { data } = await axios.get(
    `${baseApiUrl}/${resource}/${id}${queryString}`,
    {
      headers: {
        Authorization: await getToken(),
      },
    }
  );

  return data;
};
