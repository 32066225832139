import { fetchAuthSession } from "aws-amplify/auth";
import axios from "axios";
import { baseApiUrl } from "../../constants";
import { ApiRequestParams } from "./types";
import { getToken } from "./helper";
import qs from "qs";

export const listExperts = async (searchParams?: any) => {
  const queryString = qs.stringify(searchParams, { addQueryPrefix: true });

  const { data } = await axios.get(`${baseApiUrl}/experts${queryString}`, {
    headers: {
      Authorization: await getToken(),
    },
  });
  return data;
};

export const createExpert = async (expert) => {
  const { data } = await axios.post(`${baseApiUrl}/experts`, expert, {
    headers: {
      Authorization: await getToken(),
    },
  });

  return data;
};

export const updateExpert = async (expert) => {
  const { data } = await axios.patch(
    `${baseApiUrl}/experts/${expert._id}`,
    expert,
    {
      headers: {
        Authorization: await getToken(),
      },
    }
  );

  return data;
};

const useExpert = () => {
  const list = async (params?: ApiRequestParams) => {
    const { idToken } = (await fetchAuthSession()).tokens ?? {};
    const token = `Bearer ${idToken?.toString()}`;

    const { data } = await axios.get(`${baseApiUrl}/experts`, {
      headers: {
        Authorization: token,
      },
    });

    return data;
  };

  const create = async ({ firstName, lastName }) => {
    const { idToken } = (await fetchAuthSession()).tokens ?? {};
    const token = `Bearer ${idToken?.toString()}`;

    const { data } = await axios.post(
      `${baseApiUrl}/experts`,
      {
        firstName,
        lastName,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return data;
  };

  return { list, create };
};

export default useExpert;
