import React from "react";

export interface IUser {
  preferences?: any;
  company?: any;
  favourites: {
    articles: string[];
    videos: string[];
  };
}

const userContext = React.createContext<any>({ user: {}, refetch: null });

export { userContext };
