import React from "react";
import { Table } from "antd";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const columns = (t) => [
  {
    title: t("EMAIL", "Email"),
    dataIndex: "email",
    key: "email",
    render: (email: string, row: any) => {
      return <Link to={`/admin/users/${row._id}`}>{email}</Link>;
    },
  },
  {
    title: t("COMPANY", "Company"),
    dataIndex: "company",
    key: "company",
    render: (company: { name: string }) => {
      return company.name;
    },
  },
  {
    title: t("REGISTERED_EVENTS", "Registered Events"),
    dataIndex: "registeredEvents",
    key: "registeredEvents",
    render: (registeredEvents) => {
      return <>{registeredEvents.length}</>;
    },
  },
  {
    title: t("LAST_LOGIN", "Last Login"),
    dataIndex: "lastLogin",
    key: "lastLogin",
    render: (lastLogin: Date) => {
      console.log(dayjs.locale);
      console.log(dayjs.locale());
      return dayjs(lastLogin).fromNow();
    },
  },
];

const UserTable = ({ listUsers }) => {
  const { t } = useTranslation();

  const users: any = useQuery({
    queryKey: ["users"],
    queryFn: () => listUsers(),
  });

  if (users.error) {
    return <div>Error</div>;
  }

  return (
    <Table
      dataSource={users.data?.data}
      columns={columns(t)}
      loading={users.isPending}
    />
  );
};

export default UserTable;
