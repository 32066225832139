import React, { useState } from "react";
import { Modal, Form, Input } from "antd";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { createCompany } from "../Services/Api/company.api";

interface Company {
  name: string;
}

export default NiceModal.create((company?) => {
  // Use a hook to manage the modal state
  const modal = useModal();
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  console.log({ form });
  return (
    <Modal
      title={company ? "Edit Company" : "Create Company"}
      onOk={async () => {
        const newCompany = form.getFieldsValue();

        setLoading(true);
        try {
          await createCompany(newCompany);
          modal.hide();
        } catch (err) {
          console.log(err);
          setLoading(false);
        }
      }}
      confirmLoading={loading}
      open={modal.visible}
      onCancel={() => modal.hide()}
      afterClose={() => modal.remove()}
    >
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        autoComplete="off"
      >
        <Form.Item<Company>
          label="Name"
          name="name"
          rules={[{ required: true, message: "Required" }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
});
