import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Upload,
  message,
  Select,
  Checkbox,
  Collapse,
  Button,
  Popover,
} from "antd";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import useImageHook from "../Services/Api/image.api";
import type { GetProp, UploadProps } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { array, object, string } from "yup";
import { IArticle } from "src/Types/Database.types";
import { useTranslation } from "react-i18next";
import useArticle from "src/Services/Api/articles.api";
import ReactQuill from "react-quill";
import { useQuery } from "@tanstack/react-query";
import { QueryResult } from "src/Types/QueryResult";
import { listExperts } from "src/Services/Api/expert.api";
import { listTopics } from "src/Services/Api/topic.api";
import { listCategories } from "src/Services/Api/category.api";
import { locales } from "src/constants";

// Define Yup schema for event validation
let articleSchema = object({
  author: string().required(),
  image: string(),
  video: string(),
  categories: array(),
  topics: array(),
});

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

export default NiceModal.create(({ article }: { article?: IArticle }) => {
  console.log(article?._id);
  // Use a hook to manage the modal state
  const modal = useModal();
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [imageId, setImageId] = useState(article?.image._id);
  const [selectedLocale, setSelectedLocale] = useState();
  const [selectedLocales, setSelectedLocales] = useState(["en"]);

  const { create, update } = useArticle();
  const { createImage } = useImageHook();

  const { t } = useTranslation();

  const [form] = Form.useForm();

  useEffect(() => {
    if (article) {
      setSelectedLocales(Object.keys(article.locales).map((l) => l));
    }
  }, []);

  const experts: QueryResult = useQuery({
    queryKey: ["experts"],
    queryFn: () => listExperts({ limit: 1000 }),
  });

  const topics: QueryResult = useQuery({
    queryKey: ["topics"],
    queryFn: () => listTopics({ limit: 1000 }),
  });

  const categories: QueryResult = useQuery({
    queryKey: ["categories"],
    queryFn: () => listCategories({ limit: 1000 }),
  });

  const handleUpload = async ({ file }) => {
    // GET request: presigned URL
    const { url, image } = await createImage();

    console.log("image record created");
    console.log({ url, image });

    // PUT request: upload file to S3
    try {
      const result = await fetch(url, {
        method: "PUT",
        body: file,
      });
    } catch (err) {
      console.error(err);
    }

    setImageId(image._id);
  };

  const beforeUpload = (file: FileType) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange: UploadProps["onChange"] = (info) => {
    if (info.file.status === "uploading") {
      setImageLoading(true);
      return;
    }
  };

  console.log("Filtered");
  console.log(locales.filter((l) => !selectedLocales.includes(l.value)));

  const initialLocales = {};
  if (article) {
    Object.keys(article?.locales).forEach((l) => {
      initialLocales[`locales.${l}.title`] = article.locales[l].title;
      initialLocales[`locales.${l}.article`] = article.locales[l].article;
    });
  }

  const handleSelectLocale = (e) => {
    setSelectedLocale(e);
  };

  return (
    <Modal
      title={article ? "Edit Article" : "Create Article"}
      onOk={async () => {
        try {
          const result = await form.validateFields();
        } catch {
          // Ignore error
        }

        const newEvent = form.getFieldsValue();

        setLoading(true);
        newEvent.image = imageId;

        Object.keys(newEvent).forEach((key) => {
          if (key.split(".").length) {
            const [, locale, att] = key.split(".");

            if (!locale) {
              return;
            }

            if (!newEvent.locales) {
              newEvent.locales = {};
            }

            let thisLocale = newEvent.locales[locale];

            if (!thisLocale) {
              newEvent.locales[locale] = {};
            }

            newEvent.locales[locale][att] = newEvent[key];
          }

          delete newEvent[key];
        });

        console.log({ newEvent });

        let e;
        try {
          const validated = await articleSchema.validate(newEvent);

          if (!article) {
            e = await create(validated);
          } else {
            e = await update({ ...validated, _id: article._id });
          }

          modal.resolve(e);
          modal.hide();
        } catch (err) {
          console.error(err);
          setLoading(false);
        }
      }}
      confirmLoading={loading}
      open={modal.visible}
      onCancel={() => modal.hide()}
      afterClose={() => modal.remove()}
    >
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{
          ...initialLocales,
          article: article?.article,
          author: article?.author._id,
          topics: article?.topics.map((c) => c._id),
          categories: article?.categories.map((c) => c._id),
          video: article?.video,
          image: article?.image._id,
          isFeatured: article?.isFeatured,
        }}
        autoComplete="off"
      >
        <div style={{ margin: "20px" }}>
          {selectedLocales.map((locale) => {
            return (
              <Collapse
                bordered={false}
                defaultActiveKey={["1"]}
                items={[
                  {
                    key: "1",
                    label: locale,
                    children: (
                      <>
                        <Form.Item
                          label="Title"
                          name={`locales.${locale}.title`}
                          rules={[{ required: true, message: "Required" }]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Article"
                          name={`locales.${locale}.article`}
                          rules={[{ required: true, message: "Required" }]}
                        >
                          <ReactQuill
                            onChange={(e) => {
                              console.log({ e });
                            }}
                            style={{ borderRadius: "5px" }}
                          />
                        </Form.Item>
                      </>
                    ),
                    // style: panelStyle,
                  },
                ]}
              />
            );
          })}
        </div>
        {/* </Form.Item> */}

        <Popover
          content={
            <div>
              <Select
                style={{ minWidth: "200px" }}
                options={locales.filter(
                  (l) => !selectedLocales.includes(l.value)
                )}
                onChange={handleSelectLocale}
              />

              <Button
                style={{ margin: "10px" }}
                type="primary"
                onClick={() => {
                  if (selectedLocale) {
                    setSelectedLocales([...selectedLocales, selectedLocale!]);
                  }
                }}
              >
                Add
              </Button>
              {/* <a onClick={hide}>Close</a> */}
            </div>
          }
          title="Click title"
          trigger="click"
          // open={clicked}
          // onOpenChange={handleClickChange}
        >
          <Button style={{ marginBottom: "20px", marginLeft: "20px" }}>
            Add Locale
          </Button>
        </Popover>

        <Form.Item<IArticle>
          label="Author"
          name="author"
          rules={[{ required: true, message: "Required" }]}
        >
          <Select
            loading={experts.isPending}
            placeholder="Select an Expert"
            optionFilterProp="children"
            options={
              !experts.isPending
                ? experts.data.data.map((e) => {
                    return {
                      value: e._id,
                      label: `${e.firstName || ""} ${e.lastName || ""}`,
                    };
                  })
                : []
            }
          />
        </Form.Item>

        <Form.Item<IArticle>
          label="Topics"
          name="topics"
          rules={[{ required: true, message: "Required" }]}
        >
          <Select
            mode="multiple"
            loading={topics.isPending}
            placeholder="Select an Topic"
            optionFilterProp="children"
            options={
              !topics.isPending
                ? topics.data.data.map((topic) => {
                    return {
                      value: topic._id,
                      label: t(topic.name),
                    };
                  })
                : []
            }
          />
        </Form.Item>

        <Form.Item<IArticle>
          label="Categories"
          name="categories"
          rules={[{ required: true, message: "Required" }]}
        >
          <Select
            mode="multiple"
            loading={categories.isPending}
            placeholder="Select an Category"
            optionFilterProp="children"
            options={
              !categories.isPending
                ? categories.data.data.map((cat) => {
                    return {
                      value: cat._id,
                      label: t(cat.name),
                    };
                  })
                : []
            }
          />
        </Form.Item>

        <Form.Item<IArticle>
          label="Image"
          name="image"
          rules={[{ required: true, message: "Required" }]}
        >
          <Upload
            name="image"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={handleChange}
            customRequest={handleUpload}
          >
            {imageId ? (
              <img
                src={
                  article
                    ? article.image?.thumbnailUrl
                    : `https://oxyzn-images.s3.eu-west-1.amazonaws.com/upload/${imageId}.jpg`
                }
                alt="image"
                style={{ width: "100%" }}
              />
            ) : (
              <button style={{ border: 0, background: "none" }} type="button">
                {imageLoading ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>Upload</div>
              </button>
            )}
          </Upload>
        </Form.Item>

        <Form.Item<IArticle>
          label="Is Featured?"
          name="isFeatured"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
      </Form>
    </Modal>
  );
});
