import React from "react";
import { Card, Skeleton } from "antd";

const SkeletonCard = () => {
  return (
    <Card style={{ width: 240, textWrap: "pretty", margin: "10px" }}>
      <Skeleton active />
    </Card>
  );
};

export default SkeletonCard;
