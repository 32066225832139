import { getToken } from "./helper";
import axios from "axios";
import { baseApiUrl } from "../../constants";
import qs from "qs";

export const listTopics = async (searchParams?: any) => {
  const queryString = qs.stringify(searchParams, { addQueryPrefix: true });

  const { data } = await axios.get(`${baseApiUrl}/topics${queryString}`, {
    headers: {
      Authorization: await getToken(),
    },
  });

  return data;
};

export const createTopic = async (topic) => {
  await axios.post(`${baseApiUrl}/topics/`, topic, {
    headers: {
      Authorization: await getToken(),
    },
  });
};

export const updateTopic = async (topic) => {
  await axios.patch(`${baseApiUrl}/topics/${topic._id}`, topic, {
    headers: {
      Authorization: await getToken(),
    },
  });
};

export const deleteTopic = async (topicId: string) => {
  await axios.delete(`${baseApiUrl}/topics/${topicId}`, {
    headers: {
      Authorization: await getToken(),
    },
  });
};
