import React, { useEffect, useState } from "react";
import { Col, Row, Flex, Input, Pagination, Divider } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { listResource } from "src/Services/Api/resource.list";
import SkeletonCard from "src/Components/ArticleCard/SkeletonCard";
import { useDebounce } from "use-debounce";
import { useQuery } from "@tanstack/react-query";
import Filter from "src/Components/Filters/Filter";
import VideoCard from "src/Components/VideoCard/VideoCard";
import { useTranslation } from "react-i18next";

const Videos = () => {
  const { t } = useTranslation();

  const [selectedTopics, setSelectedTopics] = useState<string[]>([]);
  //   const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState<string>();
  const [debouncedValue] = useDebounce(search, 1000);

  const fetchVideos = async ({ queryKey }) => {
    const [_key] = queryKey;

    const query = {
      skip: (page - 1) * 10,
      limit: 10,
      populate: "image tags",
    };

    let whereQuery = { type: "VIDEO" };

    if (selectedTopics) {
      whereQuery["tags"] = { $in: selectedTopics };
    }

    if (debouncedValue) {
      whereQuery["title"] = { $regex: `${debouncedValue}.*`, $options: "i" };
    }

    return listResource({ queryKey }, { ...query, where: whereQuery });
  };

  const { isPending, error, data, refetch } = useQuery({
    queryKey: ["content"],
    queryFn: fetchVideos,
  });

  const handleInputChange = (event) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    refetch();
  }, [selectedTopics, debouncedValue, page]);

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        flexWrap: "wrap",
      }}
    >
      <h2
        style={{
          fontWeight: 900,
          color: "#000",
          margin: "20px",
          marginLeft: "40px",
        }}
      >
        {t("VIDEOS", "Videos")}
      </h2>

      <Row style={{ marginLeft: "20px" }}>
        <Col xs={24} sm={24} md={8} lg={8}>
          <div style={{ marginTop: "30px" }}>
            <Input
              style={{
                padding: "20px",
              }}
              addonBefore={<SearchOutlined />}
              placeholder="Search"
              onChange={handleInputChange}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <div style={{ margin: "20px" }}>
            <div style={{ margin: "10px", color: "#999" }}>Topics</div>
            <Filter
              title="Topic"
              resource="topics"
              onChange={setSelectedTopics}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={24} style={{ padding: "20px" }}>
          <Flex wrap="wrap">
            {isPending &&
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((key) => {
                return <SkeletonCard />;
              })}
            {!isPending && data?.data.length ? (
              data?.data.map((video) => {
                return <VideoCard video={video} />;
              })
            ) : (
              <div style={{ margin: "20px" }}>
                {t(
                  "NO_VIDEOS_FOUND_EXPAND_SEARCH",
                  "No Videos Found. Try expanding your search terms."
                )}
              </div>
            )}
          </Flex>
        </Col>
      </Row>
      <Row>
        <Col md={24}>
          <Pagination
            style={{
              float: "right",
              marginBottom: "40px",
              marginRight: "40px",
            }}
            pageSize={10}
            showSizeChanger={false}
            defaultCurrent={page}
            total={data?.count}
            onChange={(p) => {
              setPage(p);
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Videos;
