import React from "react";
import { Select } from "antd";
import { useTranslation } from "react-i18next";

const GenderPicker = (props) => {
  const { t } = useTranslation();
  return (
    <Select {...props} style={{ width: "100%" }} size="large">
      <Select.Option value="male">{t("MALE", "Male")}</Select.Option>
      <Select.Option value="female">{t("FEMALE", "Female")}</Select.Option>
      <Select.Option value="nonbinary">
        {t("NON_BINARY", "Non-Binary")}
      </Select.Option>
      <Select.Option value="nondisclosed">
        {t("PREFER_NOT_TO_SAY", "Prefer not to say")}
      </Select.Option>
    </Select>
  );
};

export default GenderPicker;
