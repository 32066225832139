import React, { useState } from "react";
import { Modal, Button, Form, Select } from "antd";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { updateUser } from "src/Services/Api/user.api";
import GenderPicker from "src/Components/Selects/GenderPicker";
import AgePicker from "src/Components/Selects/AgePicker";

export default NiceModal.create(() => {
  // Use a hook to manage the modal state
  const modal = useModal();

  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const handleSave = async () => {
    setLoading(true);
    const { data: user } = await updateUser({
      preferences: {
        age: 40,
        gender: "male",
      },
    });
    setLoading(false);
    modal.resolve(user);
    modal.hide();
  };

  const handleSkip = () => {
    modal.resolve();
    modal.hide();
  };

  return (
    <Modal
      title={""}
      onOk={async () => {
        modal.resolve();
        modal.hide();
      }}
      onCancel={() => {
        modal.resolve();
        modal.hide();
      }}
      open={modal.visible}
      // onCancel={() => modal.hide()}
      afterClose={() => modal.remove()}
      footer={[
        <Button type="link" onClick={handleSkip}>
          Skip for now
        </Button>,
        <Button loading={loading} type="primary" onClick={handleSave}>
          Save
        </Button>,
      ]}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1 style={{ marginBottom: "20px" }}>Help us, to help you.</h1>

        <p style={{ color: "000", fontWeight: 300 }}>
          In order to provide a better service to you and tailor the platform to
          your needs we require the following information:
        </p>
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ width: "100%" }}
          autoComplete="off"
        >
          <Form.Item
            label="Your Gender"
            name="gender"
            rules={[{ required: true, message: "Required" }]}
          >
            <GenderPicker />
          </Form.Item>
          <Form.Item
            label="Age"
            name="age"
            rules={[{ required: true, message: "Required" }]}
          >
            <AgePicker />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
});
