import axios from "axios";
import { baseApiUrl } from "../../constants";
import { getToken } from "./helper";
import qs from "qs";

export const listCategories = async (searchParams?: any) => {
  const queryString = qs.stringify(searchParams, { addQueryPrefix: true });

  const { data } = await axios.get(`${baseApiUrl}/categories${queryString}`, {
    headers: {
      Authorization: await getToken(),
    },
  });

  return data;
};

export const createCategory = async (category) => {
  await axios.post(`${baseApiUrl}/categories/`, category, {
    headers: {
      Authorization: await getToken(),
    },
  });
};

export const updateCategory = async (category) => {
  await axios.patch(`${baseApiUrl}/categories/${category._id}`, category, {
    headers: {
      Authorization: await getToken(),
    },
  });
};

export const deleteCategory = async (categoryId: string) => {
  await axios.delete(`${baseApiUrl}/categories/${categoryId}`, {
    headers: {
      Authorization: await getToken(),
    },
  });
};
