import React from "react";
import { listUsers } from "../../Services/Api/user.api";
import UserTable from "src/Components/UserTable";
import { useTranslation } from "react-i18next";

const AdminUsers = () => {
  const { t } = useTranslation();

  return (
    <div style={{ width: "100%", padding: "20px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <h1>{t("USERS", "Users")}</h1>
      </div>
      <UserTable listUsers={listUsers} />
    </div>
  );
};

export default AdminUsers;
