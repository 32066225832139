import React from "react";
import { Link, useParams } from "react-router-dom";
import { getResource } from "src/Services/Api/resource.get";
import { Button, Col, Row, Skeleton } from "antd";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarPlus, faEnvelope } from "@fortawesome/pro-light-svg-icons";
import { Image } from "src/Components/Image/Image";
import { ExpertInfo } from "src/Components/ExpertInfo/ExpertInfo";

const Expert = () => {
  const { t, i18n } = useTranslation();
  const { expert_id: id } = useParams();

  const fetchExpert = async ({ queryKey }) => {
    const [key, { id }] = queryKey;

    return getResource(
      { queryKey },
      {
        populate: ["image"],
      }
    );
  };

  const expert = useQuery({
    queryKey: ["experts", { id }],
    queryFn: fetchExpert,
  });

  if (expert.error) {
    return (
      <div>
        Return to <a href="/dashboard/experts">Experts</a>
      </div>
    );
  }
  if (expert.isPending) {
    return (
      <div>
        <Skeleton.Image
          active
          style={{
            height: "360px",
            width: "100%",
          }}
        />

        <Skeleton active />
      </div>
    );
  }

  const { firstName, lastName, locales, qualifications, insurance, email } =
    expert.data!.data;

  const locale = locales[i18n.language.split("-")[0] || "en"] || locales.en;

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ maxWidth: "800px", margin: "20px", width: "100%" }}>
        <Row>
          <Col xs={24} lg={10}>
            <Image entity={expert} loading={expert.isPending} />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <Button
                type="primary"
                style={{ margin: "10px" }}
                icon={
                  <FontAwesomeIcon
                    icon={faCalendarPlus}
                    size="xl"
                    color="#fff"
                    style={{ marginRight: "10px" }}
                  />
                }
              >
                <Link
                  target="_blank"
                  to="https://calendly.com/oxyzn/book-a-discovery-call-with-us"
                >
                  {t("BOOK_NOW", "Book Now")}
                </Link>
              </Button>
              {email && (
                <Button
                  type="default"
                  style={{ margin: "10px" }}
                  icon={
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      size="xl"
                      color="#999"
                      style={{ marginRight: "10px" }}
                    />
                  }
                >
                  <Link target="_blank" to={`mailto:${email}`}>
                    {t("MESSAGE_ME", "Message Me")}
                  </Link>
                </Button>
              )}
            </div>
            <ExpertInfo expert={expert} />
          </Col>
          <Col xs={24} lg={14}>
            <div style={{ marginLeft: "30px" }}>
              <h1 style={{ marginTop: "20px", fontWeight: "900" }}>{`${
                firstName || ""
              } ${lastName || ""}`}</h1>

              <h3 style={{ fontWeight: 300 }}>{locale.expertise}</h3>
              <div
                style={{ marginBottom: "30px" }}
                dangerouslySetInnerHTML={{ __html: locale.description }}
              ></div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default Expert;
