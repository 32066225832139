import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Typography, Spin, Button } from "antd";
import { confirmSignUp, type ConfirmSignUpInput } from "aws-amplify/auth";

const { Title } = Typography;

export const Verify = () => {
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const code = searchParams.get("code") || "";
  const username = searchParams.get("email") || "";

  const verifyEmail = async () => {
    const { isSignUpComplete, nextStep } = await confirmSignUp({
      username,
      confirmationCode: code,
    });

    console.log({ isSignUpComplete, nextStep });
  };

  useEffect(() => {
    verifyEmail();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        margin: "100px",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <img src="/logo.png" style={{ height: "60px", marginBottom: "20px" }} />

      {loading ? (
        <>
          <Title level={1}>Verifying Email</Title>
          <Spin size="large" />
        </>
      ) : (
        <>
          <Title level={1}>Verified</Title>
          <p>
            You can now{" "}
            <Button
              type="link"
              style={{ padding: 0 }}
              onClick={() => {
                navigate("/login");
              }}
            >
              Login
            </Button>
          </p>
        </>
      )}
    </div>
  );
};
