import React, { useEffect, useState } from "react";
import {
  Modal,
  TimePicker,
  DatePicker,
  Form,
  Input,
  Upload,
  message,
  Select,
  InputNumber,
  Checkbox,
  Collapse,
  Popover,
  Button,
} from "antd";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import useEventHook from "../Services/Api/event.api";
import useImageHook from "../Services/Api/image.api";
import dayjs from "dayjs";
import type { GetProp, UploadProps } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { object, string, date, number } from "yup";
import { IEvent, IUserRecord } from "src/Types/Database.types";
import { useTranslation } from "react-i18next";
import { listExperts } from "src/Services/Api/expert.api";
import { listTopics } from "src/Services/Api/topic.api";
import { listCategories } from "src/Services/Api/category.api";
import { useQuery } from "@tanstack/react-query";
import { QueryResult } from "src/Types/QueryResult";
import ReactQuill from "react-quill";
import { locales } from "src/constants";

// Define Yup schema for event validation
let eventSchema = object({
  author: string().required(),
  maximumAttendees: number().required(),
  image: string(),
  meetingLink: string(),
  date: date().required(),
  startTime: date().required(),
  endTime: date().required(),
  status: string().required().default("draft"),
});

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

export default NiceModal.create(
  ({ event, author }: { event?: IEvent; author: IUserRecord }) => {
    // Use a hook to manage the modal state
    const modal = useModal();
    const [loading, setLoading] = useState(false);
    const [imageLoading, setImageLoading] = useState(false);
    const [imageId, setImageId] = useState(event?.image._id);

    const { create, update } = useEventHook();
    const { createImage, updateImage } = useImageHook();
    const [selectedLocale, setSelectedLocale] = useState();
    const [selectedLocales, setSelectedLocales] = useState(["en"]);

    const { t } = useTranslation();

    useEffect(() => {
      if (event) {
        setSelectedLocales(Object.keys(event.locales).map((l) => l));
      }
    }, []);

    const experts: QueryResult = useQuery({
      queryKey: ["experts"],
      queryFn: () => listExperts({ limit: 1000 }),
    });

    const topics: QueryResult = useQuery({
      queryKey: ["topics"],
      queryFn: () => listTopics({ limit: 1000 }),
    });

    const categories: QueryResult = useQuery({
      queryKey: ["categories"],
      queryFn: () => listCategories({ limit: 1000 }),
    });

    const [form] = Form.useForm();

    const handleUpload = async ({ file }) => {
      // GET request: presigned URL
      const { data } = await createImage();
      const { url, image } = data;

      console.log("image record created");
      console.log({ url, image });

      // PUT request: upload file to S3
      try {
        const result = await fetch(url, {
          method: "PUT",
          body: file,
        });
      } catch (err) {
        console.error(err);
      }

      setImageId(image._id);
    };

    const beforeUpload = (file: FileType) => {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error("Image must smaller than 2MB!");
      }
      return isJpgOrPng && isLt2M;
    };

    const handleChange: UploadProps["onChange"] = (info) => {
      if (info.file.status === "uploading") {
        setImageLoading(true);
        return;
      }
    };

    const initialLocales = {};
    if (event) {
      Object.keys(event?.locales).forEach((l) => {
        initialLocales[`locales.${l}.title`] = event.locales[l].title;
        initialLocales[`locales.${l}.description`] =
          event.locales[l].description;
      });
    }

    const handleSelectLocale = (e) => {
      setSelectedLocale(e);
    };

    return (
      <Modal
        title={event ? "Edit Event" : "Create Event"}
        onOk={async () => {
          try {
            const result = await form.validateFields();
          } catch {
            // Ignore error
          }

          const newEvent = form.getFieldsValue();
          setLoading(true);
          newEvent.image = imageId;

          Object.keys(newEvent).forEach((key) => {
            if (key.split(".").length) {
              const [, locale, att] = key.split(".");

              if (!locale) {
                return;
              }

              if (!newEvent.locales) {
                newEvent.locales = {};
              }

              let thisLocale = newEvent.locales[locale];

              if (!thisLocale) {
                newEvent.locales[locale] = {};
              }

              newEvent.locales[locale][att] = newEvent[key];
            }

            delete newEvent[key];
          });

          if (author) {
            newEvent.author = author._id;
            newEvent.availableFor = [author.company._id];
          }

          let e;
          try {
            const validated = await eventSchema.validate(newEvent);

            console.log({ validated });

            if (!event) {
              e = await create(validated);
            } else {
              e = await update({ ...validated, _id: event._id });
            }

            modal.resolve(e);
            modal.hide();
          } catch (err) {
            console.error(err);
            setLoading(false);
          }
        }}
        confirmLoading={loading}
        open={modal.visible}
        onCancel={() => modal.hide()}
        afterClose={() => modal.remove()}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{
            ...initialLocales,
            author: event?.author._id,
            status: event?.status,
            title: event?.title,
            description: event?.description,
            maximumAttendees: event?.maximumAttendees || 10,
            topics: event?.topics.map((t) => t._id),
            categories: event?.categories.map((c) => c._id),
            meetingLink: event?.meetingLink,
            image: event?.image._id,
            date: dayjs(event?.date),
            startTime: dayjs(event?.startTime),
            endTime: dayjs(event?.endTime),
            level: event?.level,
            isFeatured: event?.isFeatured,
          }}
          autoComplete="off"
        >
          <div style={{ margin: "20px" }}>
            {selectedLocales.map((locale) => {
              return (
                <Collapse
                  bordered={false}
                  defaultActiveKey={["1"]}
                  items={[
                    {
                      key: "1",
                      label: locale,
                      children: (
                        <>
                          <Form.Item
                            label="Title"
                            name={`locales.${locale}.title`}
                            rules={[{ required: true, message: "Required" }]}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            label="Description"
                            name={`locales.${locale}.description`}
                            rules={[{ required: true, message: "Required" }]}
                          >
                            <ReactQuill
                              onChange={(e) => {
                                console.log({ e });
                              }}
                              style={{ borderRadius: "5px" }}
                            />
                          </Form.Item>
                        </>
                      ),
                      // style: panelStyle,
                    },
                  ]}
                />
              );
            })}
          </div>

          <Popover
            content={
              <div>
                <Select
                  style={{ minWidth: "200px" }}
                  options={locales.filter(
                    (l) => !selectedLocales.includes(l.value)
                  )}
                  onChange={handleSelectLocale}
                />

                <Button
                  style={{ margin: "10px" }}
                  type="primary"
                  onClick={() => {
                    if (selectedLocale) {
                      setSelectedLocales([...selectedLocales, selectedLocale!]);
                    }
                  }}
                >
                  Add
                </Button>
                {/* <a onClick={hide}>Close</a> */}
              </div>
            }
            title="Click title"
            trigger="click"
            // open={clicked}
            // onOpenChange={handleClickChange}
          >
            <Button style={{ marginBottom: "20px", marginLeft: "20px" }}>
              Add Locale
            </Button>
          </Popover>

          {!author && (
            <Form.Item<IEvent>
              label="Author"
              name="author"
              rules={[{ required: true, message: "Required" }]}
            >
              <Select
                loading={experts.isPending}
                placeholder="Select an Expert"
                optionFilterProp="children"
                options={
                  !experts.isPending
                    ? experts.data.data.map((e) => {
                        return {
                          value: e._id,
                          label: `${e.firstName || ""} ${e.lastName || ""}`,
                        };
                      })
                    : []
                }
              />
            </Form.Item>
          )}

          <Form.Item<IEvent>
            label="Status"
            name="status"
            rules={[{ required: true, message: "Required" }]}
          >
            <Select
              placeholder="Select an Level"
              optionFilterProp="children"
              options={[
                {
                  label: "Draft",
                  value: "draft",
                },
                {
                  label: "Published",
                  value: "published",
                },
                {
                  label: "Archived",
                  value: "archived",
                },
              ]}
            />
          </Form.Item>

          <Form.Item<IEvent>
            label="Max attendees"
            name="maximumAttendees"
            rules={[{ required: true, message: "Required" }]}
          >
            <InputNumber />
          </Form.Item>

          <Form.Item<IEvent>
            label="Zoom Link"
            name="meetingLink"
            rules={[{ required: true, message: "Required" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item<IEvent>
            label="Level"
            name="level"
            rules={[{ required: true, message: "Required" }]}
          >
            <Select
              placeholder="Select an Level"
              optionFilterProp="children"
              options={[
                {
                  label: "Beginner",
                  value: "Beginner",
                },
                {
                  label: "Intermediate",
                  value: "Intermediate",
                },
                {
                  label: "Advanced",
                  value: "Advanced",
                },
              ]}
            />
          </Form.Item>

          <Form.Item<IEvent>
            label="Topics"
            name="topics"
            rules={[{ required: true, message: "Required" }]}
          >
            <Select
              mode="multiple"
              loading={topics.isPending}
              placeholder="Select an Topic"
              optionFilterProp="children"
              options={
                !topics.isPending
                  ? topics.data.data.map((topic) => {
                      return {
                        value: topic._id,
                        label: t(topic.name),
                      };
                    })
                  : []
              }
            />
          </Form.Item>

          <Form.Item<IEvent>
            label="Categories"
            name="categories"
            rules={[{ required: true, message: "Required" }]}
          >
            <Select
              mode="multiple"
              loading={categories.isPending}
              placeholder="Select an Category"
              optionFilterProp="children"
              options={
                !categories.isPending
                  ? categories.data.data.map((cat) => {
                      return {
                        value: cat._id,
                        label: t(cat.name),
                      };
                    })
                  : []
              }
            />
          </Form.Item>

          <Form.Item<IEvent>
            label="Image"
            name="image"
            rules={[{ required: true, message: "Required" }]}
          >
            <Upload
              name="image"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              beforeUpload={beforeUpload}
              onChange={handleChange}
              customRequest={handleUpload}
            >
              {event?.image && (
                <img
                  src={event.image.thumbnailUrl}
                  alt="image"
                  style={{ width: "100%" }}
                />
              )}
              {imageId ? (
                <img
                  src={`https://oxyzn-images-dev.s3.eu-west-1.amazonaws.com/upload/${imageId}.jpg`}
                  alt="image"
                  style={{ width: "100%" }}
                />
              ) : (
                <button style={{ border: 0, background: "none" }} type="button">
                  {imageLoading ? <LoadingOutlined /> : <PlusOutlined />}
                  <div style={{ marginTop: 8 }}>Upload</div>
                </button>
              )}
            </Upload>
          </Form.Item>

          <Form.Item<IEvent>
            label="Is Featured?"
            name="isFeatured"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>

          <Form.Item<IEvent>
            label="Date"
            name="date"
            rules={[{ required: true, message: "Required" }]}
          >
            <DatePicker />
          </Form.Item>

          <Form.Item<IEvent>
            label="Start Time"
            name="startTime"
            rules={[{ required: true, message: "Required" }]}
          >
            <TimePicker showSecond={false} format="HH:mm" minuteStep={15} />
          </Form.Item>

          <Form.Item<IEvent>
            label="End Time"
            name="endTime"
            rules={[{ required: true, message: "Required" }]}
          >
            <TimePicker showSecond={false} format="HH:mm" minuteStep={15} />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
);
