import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppSidebar as Sidebar } from "../Components/Sidebar/index";
import { Outlet } from "react-router-dom";
import { Layout, theme, Menu, Grid, Button } from "antd";
import { signOut } from "@aws-amplify/auth";
import { ErrorBoundary } from "react-error-boundary";
import { ToastContainer } from "react-toastify";
import NiceModal from "@ebay/nice-modal-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket, faBars } from "@fortawesome/pro-light-svg-icons";
import WelcomeModal from "../Modals/Onboarding/01.Thanks";
import GetInfoModal from "../Modals/Onboarding/02.CollectInfo";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import {
  godSection,
  adminSection,
  generalSection,
} from "../Components/Sidebar/index";

const { Header, Content, Footer } = Layout;
const { useBreakpoint } = Grid;

const handleSignOut = async () => {
  try {
    await signOut({ global: true });
    return true;
  } catch (error) {
    console.log("error signing out: ", error);
    return false;
  }
};

function fallbackRender({ error, resetErrorBoundary }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
    </div>
  );
}

export const Dashboard = ({ userRecord }) => {
  console.log("Dashboard Render");
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const { xs } = useBreakpoint();

  const { t } = useTranslation();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  let imageUrl = "/images/yoga.png";
  let height = "260px";

  if (pathname.includes("article")) {
    imageUrl = "/images/reading.png";
    height = "200px";
  }

  if (pathname.includes("video")) {
    imageUrl = "/images/yoga2.png";
    height = "200px";
  }

  // if (pathname.includes("event")) {
  //   imageUrl = "/images/meditation.png";
  //   height = "260px";
  // }

  const logout = {
    icon: (
      <FontAwesomeIcon
        style={{ height: "18px", color: "#fff" }}
        icon={faRightFromBracket}
        id="logout"
      />
    ),
    key: "logout",
    label: "",
  };

  const menu = {
    icon: (
      <FontAwesomeIcon
        style={{ height: "18px", color: "#fff" }}
        icon={faBars}
      />
    ),
    key: "sidemenu",
    label: "",
    children: [generalSection(t)],
  };

  let items = [logout];

  if (xs) {
    items = [menu, logout];
  }

  const showWelcomeModal = (article?) => {
    if (userRecord && !userRecord.preferences) {
      // Show a modal with arguments passed to the component as props
      NiceModal.show(WelcomeModal).then(() => {
        NiceModal.show(GetInfoModal).then((user) => {
          if (user) {
            localStorage.setItem("userRecord", JSON.stringify(user));
          }
        });
      });
    }
  };

  useEffect(() => {
    showWelcomeModal();
  }, []);

  let wave1 = document.getElementById("wave1");
  let wave2 = document.getElementById("wave2");
  let wave3 = document.getElementById("wave3");
  let wave4 = document.getElementById("wave4");

  window.addEventListener("scroll", function () {
    let value = window.scrollY / 3;

    if (wave1) wave1!.style.backgroundPositionX = 400 + value * 4 + "px";
    if (wave2) wave2!.style.backgroundPositionX = 300 + value * -4 + "px";
    if (wave3) wave3!.style.backgroundPositionX = 200 + value * 2 + "px";
    if (wave4) wave4!.style.backgroundPositionX = 100 + value * -2 + "px";
  });

  const user = JSON.parse(localStorage.getItem("userInfo") || "{}");

  return (
    <Layout style={{ minHeight: "100vh" }} hasSider>
      <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
      <Layout>
        <Header
          style={{
            padding: 0,
            background: "#478DC0",
            height: "70px",
            // borderColor: "#000",
            // border: "1px solid",
            // boxShadow: "0px 4px 4px #ddd",
          }}
        >
          <div className="demo-logo" />
          <Menu
            mode="horizontal"
            // defaultSelectedKeys={["2"]}
            items={items}
            style={{
              flex: 1,
              minWidth: 0,
              height: "70px",

              // justifyContent: xs ? "space-between" : "end",
              backgroundColor: "transparent",
            }}
            onClick={async (e) => {
              console.log("click");
              if (e.key === "logout") {
                await handleSignOut();
                navigate("/login");
              } else {
                navigate(`/dashboard/${e.key}`);
              }
            }}
          />
        </Header>

        <Content
          style={{
            // margin: "24px 26px",
            overflow: "initial",
          }}
        >
          <div
            style={{
              // padding: 24,
              // boxShadow: "0px 0px 8px #ddd",
              background: colorBgContainer,
              // borderRadius: borderRadiusLG,
              width: "100%",

              // backgroundColor: "#fbc2eb",
              // backgroundImage: "linear-gradient(45deg, #fff, #fff, #fbc2eb)",
            }}
          >
            <section>
              <div className="wave" id="wave1"></div>
              <div className="wave" id="wave2"></div>
              <div className="wave" id="wave3"></div>
              <div className="wave" id="wave4"></div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                {user?.given_name ? (
                  <h1
                    style={{
                      marginLeft: "40px",
                      marginTop: "-80px",
                      zIndex: "102",
                      fontSize: "32px",
                      fontWeight: 900,
                      color: "#000",
                    }}
                    className="welcome_back"
                  >
                    {`${t("WELCOME_BACK", "Welcome Back")}, ${
                      user?.given_name || ""
                    }`}
                  </h1>
                ) : (
                  <h1
                    style={{
                      marginLeft: "40px",
                      marginTop: "-80px",
                      zIndex: "102",
                      fontSize: "32px",
                      fontWeight: 900,
                      color: "#fff",
                    }}
                    className="welcome_back"
                  >
                    {t("WELCOME_BACK", "Welcome Back")}!
                  </h1>
                )}
                <img
                  style={{
                    marginTop: "-120px",
                    marginRight: "20px",
                    // width: "400px",
                    height,
                    zIndex: 101,
                  }}
                  src={imageUrl}
                  alt="mySvgImage"
                />
              </div>
            </section>
            <ErrorBoundary fallbackRender={fallbackRender}>
              <div>
                <ToastContainer />
                <Outlet />
              </div>
            </ErrorBoundary>
          </div>
        </Content>
        <Footer
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          Oxyzn Portal ©{new Date().getFullYear()} Created by Oxyzn
          <Button
            type="link"
            onClick={() => {
              navigate("/dashboard/privacy-policy");
            }}
          >
            Privacy Policy
          </Button>
        </Footer>
      </Layout>
    </Layout>
  );
};
