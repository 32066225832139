import React, { useEffect, useState } from "react";
import { Button, Row, Spin } from "antd";
import dayjs from "dayjs";
import Timestamp from "../Timestamp/Timestamp";
import EventTooltip from "../EventTooltip/EventTooltip";
import isoWeek from "dayjs/plugin/isoWeek";
import isBetween from "dayjs/plugin/isBetween";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-light-svg-icons";
import { useQuery } from "@tanstack/react-query";
import { listEvents } from "src/Services/Api/event.api";

dayjs.extend(isoWeek);
dayjs.extend(isBetween);

const OnThisWeek = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<any>();
  const [offset, setOffset] = useState(0);

  const fetchEvents = async ({ queryKey }) => {
    console.log({ queryKey });
    const offset = queryKey[1];
    console.log({ offset });
    const params = {
      where: {
        ...queryKey[1],
        date: { $gte: dayjs().add(offset, "weeks").startOf("week").format() },
        status: { $eq: "published" },
      },
      limit: 100,
      populate: "image topics categories",
    };

    console.log("Fetching events");
    return listEvents(params);
  };

  const events = useQuery({
    queryKey: ["events", offset],
    queryFn: fetchEvents,
  });

  console.log({ events });

  useEffect(() => {
    const week = dayjs().add(offset, "weeks");
    const calendarData: any[] = [];

    for (let i = 1; i < 6; i++) {
      const day = week.startOf("week").add(i, "days");
      calendarData.push(day);
    }

    setData(calendarData);
  }, [offset]);

  if (!data) {
    return <div></div>;
  }

  if (!events?.data) {
    return <div></div>;
  }

  return (
    <>
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Button
          style={{ marginTop: "75px" }}
          type="link"
          onClick={() => {
            setOffset(offset - 1);
          }}
        >
          <FontAwesomeIcon icon={faChevronLeft} size="xl" color="#5B9CD1" />
        </Button>
        {data.length &&
          data.map((d) => {
            console.log({ d });

            const eventsOnDay: any[] = [];
            events.data?.data?.forEach((e) => {
              if (dayjs(e.startTime).isSame(d, "day")) {
                eventsOnDay.push(e);
              }
            });

            if (!d) return;

            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  borderRadius: "10px",
                  backgroundColor: d.isAfter(dayjs(), "day")
                    ? "#cae4f4"
                    : "#e4f1f9",
                  backgroundImage:
                    d.isSame(dayjs(), "day") &&
                    "linear-gradient(45deg, #65BEE9, #DAE5F1)",
                  margin: "10px",
                  // minWidth: "100px",
                  width: "180px",
                  border: d.isSame(dayjs(), "day") && "3px solid",
                  borderColor: d.isSame(dayjs(), "day") && "#347ACO",
                }}
              >
                <h2 style={{ marginTop: "20px" }}>{d.format("ddd")}</h2>
                <h1 style={{ fontWeight: 900, fontSize: "34px" }}>
                  {d.format("D")}
                </h1>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    width: "100%",
                  }}
                >
                  {events.isPending && (
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Spin />
                    </div>
                  )}
                  {!eventsOnDay.length && (
                    <div style={{ margin: "30px" }}></div>
                  )}
                  {eventsOnDay.map((event) => {
                    return (
                      <div
                        style={{
                          marginTop: "30px",
                          width: "100%",
                          marginBottom: "30px",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#8BBBDF",
                            padding: "5px",
                            paddingTop: "2px",
                          }}
                        >
                          <Timestamp
                            color={"#fff"}
                            startTime={event.startTime}
                            endTime={event.endTime}
                          />
                        </div>
                        <div
                          style={{
                            marginLeft: "20px",
                            marginRight: "20px",
                          }}
                        >
                          <EventTooltip
                            event={event}
                            style={
                              d.isSame(dayjs(), "day") && { fontWeight: 600 }
                            }
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        <Button
          style={{ marginTop: "75px" }}
          type="link"
          onClick={() => {
            setOffset(offset + 1);
          }}
        >
          <FontAwesomeIcon icon={faChevronRight} size="xl" color="#5B9CD1" />
        </Button>
      </Row>
    </>
  );
};

export default OnThisWeek;
