import { Skeleton } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

export const ExpertInfo = ({ expert }) => {
  const { t } = useTranslation();

  if (expert.isPending) {
    return <Skeleton active />;
  }

  const { qualifications, insurance } = expert.data.data;
  return (
    <>
      {qualifications.length ? (
        <>
          <h3
            style={{ fontWeight: "900", fontSize: "14px", marginBottom: "0" }}
          >
            {t("QUALIFICATIONS", "Qualifications")}
          </h3>
          <ul>
            {qualifications.map((q) => (
              <li>{q}</li>
            ))}
          </ul>
        </>
      ) : (
        <div></div>
      )}
      {insurance && (
        <>
          <h3
            style={{ fontWeight: "900", fontSize: "14px", marginBottom: "0" }}
          >
            {t("INSURANCE", "Insurance")}
          </h3>
          <div>{insurance ? "Yes" : "No"}</div>
        </>
      )}
    </>
  );
};
