import React, { useState, useEffect } from "react";
import { Button, Input } from "antd";
import { setUserPassword } from "src/Services/Api/user.api";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const Confirmation = () => {
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [passwordStatus, setPasswordStatus]: ["" | "error", any] = useState("");
  const [confirmStatus, setConfirmStatus]: ["" | "error", any] = useState("");

  const { confirmation_id: id } = useParams();
  const validatePassword = (pw, length) => {
    return (
      /[A-Z]/.test(pw) &&
      /[a-z]/.test(pw) &&
      /[0-9]/.test(pw) &&
      /[^A-Za-z0-9]/.test(pw) &&
      pw.length > length
    );
  };

  useEffect(() => {
    if (password) {
      if (!validatePassword(password, 8)) {
        setPasswordStatus("error");
      } else {
        setPasswordStatus("");
      }
    }

    if (confirm)
      if (password !== confirm) {
        setConfirmStatus("error");
      } else {
        setConfirmStatus("");
      }
  }, [password, confirm]);

  return (
    <div
      style={{
        marginTop: "80px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src="/logo.png" style={{ height: "60px", marginBottom: "20px" }} />
        <h1>{t("CREATE_YOUR_PASSWORD", "Create your Password")}</h1>
        <div style={{ marginTop: "20px" }}>
          {t("ENTER_PASSWORD", "Enter Password:")}
        </div>
        <Input.Password
          status={passwordStatus}
          style={{ width: "400px" }}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
        <div style={{ marginTop: "20px" }}>
          {t("VERIFY_PASSWORD", "Verify Password:")}
        </div>
        <Input.Password
          status={confirmStatus}
          onChange={(e) => {
            setConfirm(e.target.value);
          }}
        />
      </div>
      <Button
        type="primary"
        style={{ marginTop: "20px" }}
        disabled={passwordStatus !== "" || confirmStatus !== ""}
        loading={loading}
        onClick={async () => {
          setLoading(true);
          await setUserPassword({ confirmationGuid: id, password });
          navigate("/login");
        }}
      >
        {t("SET_PASSWORD", "Set Password")}
      </Button>
    </div>
  );
};
