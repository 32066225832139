export const awsExports = {
  aws_project_region: "eu-west-1",
  aws_cognito_region: "eu-west-1",
  aws_user_pools_id: "eu-west-1_XgVsRaYTj", // Production
  // aws_user_pools_id: "eu-west-1_9WqhfXCiB", // Dev
  aws_user_pools_web_client_id: "u78640tb2kk04bjsn455oep2p", // Production
  // aws_user_pools_web_client_id: "2231rp60ddkd930lk2fgm8m6u5", // Dev
  oauth: {
    domain: "oxyzn.auth.eu-west-1.amazoncognito.com",
    scope: ["email", "openid", "aws.cognito.signin.user.admin", "profile"],
    redirectSignIn: "http://localhost:3000/callback",
    // redirectSignOut: "http://localhost:3000/callback",
    responseType: "code",
  },
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: [],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      "REQUIRES_LOWERCASE",
      "REQUIRES_UPPERCASE",
      "REQUIRES_NUMBERS",
      "REQUIRES_SYMBOLS",
    ],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
} as const;
