import { useState } from "react";
import { fetchAuthSession } from "aws-amplify/auth";
import axios from "axios";
import { baseApiUrl } from "../../constants";
import qs from "qs";
import { getToken } from "./helper";

export const listArticles = async (searchParams?) => {
  const queryString = qs.stringify(searchParams, { addQueryPrefix: true });

  const { data } = await axios.get(`${baseApiUrl}/content${queryString}`, {
    headers: {
      Authorization: await getToken(),
    },
  });

  return data;
};

const useArticle = () => {
  const [loading, setLoading] = useState(false);
  const list = async (searchParams?) => {
    setLoading(true);

    const { idToken } = (await fetchAuthSession()).tokens ?? {};
    const token = `Bearer ${idToken?.toString()}`;

    const queryString = qs.stringify(searchParams, { addQueryPrefix: true });

    console.log(queryString);

    const { data } = await axios.get(`${baseApiUrl}/articles${queryString}`, {
      headers: {
        Authorization: token,
      },
    });

    setLoading(false);
    return data;
  };

  const get = async (id, query) => {
    setLoading(true);

    const queryString = qs.stringify(query, { addQueryPrefix: true });

    console.log(queryString);

    const { idToken } = (await fetchAuthSession()).tokens ?? {};
    const token = `Bearer ${idToken?.toString()}`;

    const { data } = await axios.get(
      `${baseApiUrl}/articles/${id}${queryString}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    setLoading(false);
    return data;
  };

  const create = async (inputData) => {
    const { idToken } = (await fetchAuthSession()).tokens ?? {};
    const token = `Bearer ${idToken?.toString()}`;

    const { data } = await axios.post(`${baseApiUrl}/articles`, inputData, {
      headers: {
        Authorization: token,
      },
    });

    return data;
  };

  const update = async (event) => {
    const { idToken } = (await fetchAuthSession()).tokens ?? {};
    const token = `Bearer ${idToken?.toString()}`;

    const { data } = await axios.patch(
      `${baseApiUrl}/articles/${event._id}`,
      event,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return data;
  };

  return { get, list, create, update, loading };
};

export default useArticle;
