import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { Button, Popconfirm, Skeleton } from "antd";
import EventDetails from "../../Components/EventDetails";
import EventRate from "../../Components/EventRate";
import useEventHook from "src/Services/Api/event.api";
import { Link, useParams } from "react-router-dom";
import useUser, { getUser } from "src/Services/Api/user.api";
import dayjs from "dayjs";
import EventRegistrationModal from "../../Modals/EventRegistration";
import { useMutation } from "@tanstack/react-query";
import { createMetric } from "src/Services/Api/metric";
import { useTranslation } from "react-i18next";

const Event = () => {
  const { i18n } = useTranslation();
  const [event, setEvent] = useState<Record<string, any>>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [user, setUser] = useState<Record<string, any>>();
  const [loading, setLoading] = useState(false);
  const [isPast, setIsPast] = useState(false);

  const { event_id } = useParams();

  const { t } = useTranslation();

  const { get } = useEventHook();
  const { registerUserForEvent, unregisterUserForEvent } = useUser();

  const fetchEvent = async () => {
    const { data } = await get(event_id!, {
      populate: "image categories author",
    });
    setEvent(data);
    if (dayjs(data.startTime).isBefore(dayjs())) {
      setIsPast(true);
    }
  };

  const fetchUser = async () => {
    const { data } = await getUser({ id: "me" });
    setUser(data);
  };

  const mutation = useMutation({
    mutationFn: () => {
      return createMetric({
        type: "event.read",
        asset: event_id,
      });
    },
  });

  useEffect(() => {
    mutation.mutate();
    fetchEvent();
    fetchUser();
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleRegister = async () => {
    setLoading(true);
    // Register user for event
    if (user!.registeredEvents.includes(event_id)) {
      const { data } = await unregisterUserForEvent(event_id);
      console.log("Got new user from unregister event");
      setUser(data);
      setLoading(false);
    } else {
      const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
      if (!userInfo.email) {
        throw new Error("User has no email address");
      }
      const { data } = await registerUserForEvent(event_id, userInfo.email);
      console.log("Got new user from register event");
      setUser(data);
      setLoading(false);
      showModal();
    }
  };

  if (!event || !user)
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={7}>
          <div style={{ display: "flex", margin: "20px", width: "100%" }}>
            <Skeleton.Image
              active
              style={{
                height: "360px",
                width: "100%",
              }}
            />
          </div>
          <Skeleton active />
        </Grid>
        <div style={{ height: "100%", display: "flex" }}></div>
      </Grid>
    );

  if (!event._id)
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1>Sorry, the event you're looking for cannot be found</h1>
        <span style={{ fontWeight: "100" }}>
          Back to <Link to="/dashboard/events">events</Link>
        </span>
      </div>
    );

  const isRegistered = user.registeredEvents.includes(event_id);
  const title = isRegistered ? "Unregister for Event" : "Register for Event";

  const RegBtn = ({ onClick }) => {
    return (
      <Button
        type="primary"
        size="large"
        style={{ color: "#fff", backgroundColor: "#598BBC", fontWeight: "500" }}
        loading={loading}
        onClick={onClick}
      >
        {title}
      </Button>
    );
  };

  const RegisterBtn = () => {
    const { t } = useTranslation();
    if (isRegistered) {
      return <RegBtn onClick={handleRegister} />;
    } else {
      return (
        <Popconfirm
          title={t("PLEASE_CONFIRM", "Please Confirm")}
          description={t(
            "CONFIRM_YOU_WISH_TO_REGISTER",
            "Confirm you wish to register for this event"
          )}
          onConfirm={handleRegister}
        >
          <RegBtn onClick={() => {}} />
        </Popconfirm>
      );
    }
  };

  const locale =
    event.locales[i18n.language.split("-")[0] || "en"] || event.locales.en;

  return (
    <>
      <EventRegistrationModal
        event={event}
        isModalOpen={isModalOpen}
        handleOk={handleOk}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={7}>
          <div style={{ display: "flex", margin: "20px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                backgroundImage: `url(${event.image.url})`,
                height: "360px",
                width: "100%",
                backgroundRepeat: "no-repeat",
                // @ts-ignore
                backgroundColor: (t): string | number =>
                  t.palette.mode === "light"
                    ? t.palette.grey[50]
                    : t.palette.grey[900],
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: "20px",
              }}
            >
              <h1 style={{ fontWeight: 900, color: "#fff", padding: "20px" }}>
                {locale.title}
              </h1>
            </div>
          </div>
          <div style={{ margin: "20px" }}>
            {user && !isPast && <RegisterBtn />}
          </div>
          <div style={{ margin: "20px" }}>
            {t("RUN_BY", "Run by")}
            <Link
              type="link"
              style={{ padding: "8px" }}
              to={`/dashboard/experts/${event.author._id}`}
            >
              {event?.author.firstName} {event?.author.lastName}
            </Link>
          </div>
          <div
            style={{ margin: "20px" }}
            dangerouslySetInnerHTML={{ __html: locale.description }}
          ></div>
        </Grid>
        <Grid item xs={12} md={12} lg={5}>
          <EventDetails event={event} />
          {isPast && <EventRate event={event} />}
        </Grid>

        <div style={{ height: "100%", display: "flex" }}></div>
      </Grid>
    </>
  );
};

export default Event;
